export default {
  app: {
    header: {
      language: "Idioma"
    }
  },
  home: {
    title: "Verificación Online de Certificados",
    trid: "identificador único de transaccion (trid)",
    csv: "identificador único de certificado (csv)",
    description:
      "Complete los siguientes datos para verificar la autenticidad de la declaración de finalización o certificado que obra en su poder.",
    subtitle: "CÓDIGO DE VALIDACIÓN",
    validate: "Validar",
    resultError: "Por favor, revise los datos introducidos",
    messagesInputs: {
      required: "Requerido",
      minimo: "Minimo 4 caracteres",
      maximo: "Minimo 1000 caracteres"
    }
  },
  confirmation: {
    title: "Sus datos corresponden con la siguiente certificación",
    subtitle:
      "Puede descargar el certificado en el botón inferior y comprobar que coincide con la información en su poder.",
    download: "Descargar",
    back: "Volver",
    certificateDownloaded: "Su certificado se ha descargado correctamente"
  },
  helpDialog: {
    messageSent: {
      title: "Contactar con soporte",
      subtitle:
        "Se ha enviado su solicitud a nuestro departamento de soporte, un agente se pondrá en contacto con usted a la mayor brevedad posible.",
      back: "Volver"
    },
    text: "¿Necesita ayuda?",
    contactWithSupport: "Contactar con soporte",
    dialogPhrase:
      "Rellene los datos y el equipo de soporte técnico le responderá a la mayor brevedad posible. Horarios de atención, laborables:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>Lunes a Viernes</strong>: 8:00 h - 17:00 h</p>",
    message: "Mensaje",
    sendRequest: "Enviar Solicitud",
    requiredEmailFormat: "Formato de email incorrecto",
    requiredEmail: "Email requerido",
    requiredMessage: "Mensaje requerido",
    requiredName: "Nombre requerido",
    email: "E-mail",
    name: "Nombre"
  },
  termsAndConditions: {
    title: "Términos y Condiciones",
    termsConditions1:
      " CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (en adelante CCOMMS) en su condición de titular y responsable de la presente APP, en cumplimiento de lo establecido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, pone a disposición de los usuarios la siguiente información genera de esta APP:",
    companyInfo:
      "<p class='mb-1'><strong>Titular</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F</strong>: CB-86414000 </p><p class='mb-1'><strong>Domicilio Social</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Datos Registrales</strong>: Registro Mercantil de Madrid al Tomo 29.801, Folio 98, Sección 8ª, Hoja número M-536210.</p><p class='mb-1'><strong>Contacto: Teléfono</strong>: 916 895 665</p><p class='mb-1'><strong>Email</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Correo electrónico del Delegado de Protección de Datos</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Delegado de Protección de Datos de MailTecK'>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.- OBJETO",
    termsConditionsObjetoParrafo1:
      "Las presentes Condiciones Generales de Uso (en adelante, las “CGU”) regulan el uso de la Aplicación Validacion de certificado (en adelante, la APP) que CCOMMS pone a disposición de los usuarios que accedan al mismo con el fin de proceder a poder realizar la solicitud realizada.",
    termsConditionsObjetoParrafo2:
      "Por tanto, las presentes CGU se aplicarán tanto a la actividad promocional y de suministro de información que se efectúa a través de la APP como a la prestación de los servicios ofrecidos en el mismo, de tal manera que las mismas regirán en todo momento tanto para la simple navegación por la APP, como para la contratación de servicios en el marco de la APP, si bien estas últimas actividades adicionalmente, se someterán tanto a las CGU descritas a continuación, como a las Condiciones Generales de Contratación aplicables, y las Condiciones Particulares, que en su caso, pudieran existir.",
    termsConditionsUsuariosTitulo: "II.- USUARIOS",
    termsConditionsUsuariosParrafo1:
      "El acceso a y/o uso de la APP, atribuye a quien lo realiza la condición de usuario, aceptando, desde ese mismo momento, plenamente y sin reservas alguna, las presentes CGU, así como las Condiciones Particulares que, en su caso, complementen, modifiquen o sustituyan las CGU en relación con determinados servicios y contenidos de la APP, obligándose a respetar las restricciones de uso de dicha página establecidas por CCOMMS en cada momento.",
    termsConditionsAccesoAPPTitulo: "III.- ACCESO A LA APP",
    termsConditionsAccesoAPPParrafo1:
      "El acceso y uso de la APP tiene carácter restringido y para poder acceder a la misma será necesario el registro previo del usuario.",
    termsConditionsUsoAPPTitulo: "IV.- USO DE LA APP",
    termsConditionsUsoAPPParrafo1:
      "El usuario se compromete a utilizar la APP, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe, los usos generalmente aceptados y el orden público. Asimismo, queda prohibido, el uso de la APP con fines ilícitos o lesivos contra CCOMMS o cualquier tercero, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento de la APP.",
    termsConditionsResposabilidadTitulo:
      "V-. RESPONSABILIDAD DEL USUARIO POR DAÑOS Y PERJUICIOS",
    termsConditionsResposabilidadParrafo1:
      "El uso de la APP se realizará bajo la única y exclusiva responsabilidad del usuario. Dicha responsabilidad se extenderá al uso, por parte del usuario o de cualquier tercero, de cualesquiera contraseña o similares asignadas para el acceso a la APP o a cualesquiera de sus servicios.",
    termsConditionsResposabilidadParrafo2:
      "Sin perjuicio de lo anterior, CCOMMS se reserva el derecho a denegar o inhabilitar en cualquier momento y sin necesidad de aviso previo, el acceso a la APP o al acceso restringido, a aquellos usuarios que incumplan estas CGU, las normas establecidas por CCOMMS o sus colaboradores o puedan perturbar el buen funcionamiento, imagen, credibilidad y/o prestigio de CCOMMS o sus colaboradores.",
    termsConditionsResposabilidadParrafo3:
      "En este sentido, CCOMMS se reserva la facultad de cancelar o restringir, el acceso a de la APP a determinados Usuarios, en caso de observar cualesquiera conductas que a juicio de CCOMMS resulten contrarias a estas Condiciones de Uso, la Ley, las normas establecidas por CCOMMS o sus colaboradores o puedan perturbar el buen funcionamiento, imagen, credibilidad y/o prestigio de CCOMMS o sus colaboradores. Asimismo, CCOMMS podrá exigir responsabilidad a los Usuarios por los daños y perjuicios derivados del mal uso de la APP.",
    termsConditionsResposabilidadParrafo4:
      "CCOMMS no garantiza la licitud, fiabilidad y utilidad, así como su veracidad o exactitud, de los contenidos que los usuarios transmitan durante la utilización de la APP.",
    termsConditionsResposabilidadParrafo5:
      "CCOMMS no controla la utilización que los usuarios hacen de la APP, ni garantiza que lo hagan de forma conforme a las presentes Condiciones Generales.",
    termsConditionsResposabilidadParrafo6:
      "CCOMMS no controla ni garantiza la ausencia de virus informáticos en los servicios prestados por terceros a través de la APP que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos y ficheros electrónicos almacenados en su sistema informático.",
    termsConditionsPropiedadIntelectualTitulo: "VI.- PROPIEDAD INTELECTUAL",
    termsConditionsPropiedadIntelectualParrafo1:
      'Cualquier uso o modificación del Material o de los Contenidos para cualquier otro fin distinto del autorizado en las Condiciones Generales será considerado una violación de las leyes internacionales del "copyright", que protegen los derechos de autor.',
    termsConditionsPropiedadIntelectualParrafo2:
      "Respecto de los contenidos, se prohíbe:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "Su reproducción, copia, distribución, modificación o manipulación, de cualquier forma y con cualquier finalidad, a menos que se cuente con la autorización previa y por escrito de sus legítimos titulares o resulte legalmente permitido.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "Cualquier vulneración de los derechos de CCOMMS o de sus legítimos titulares sobre los mismos.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "Su utilización para todo tipo de fines comerciales o publicitarios, distintos de los estrictamente permitidos.",
    termsConditionsPropiedadIntelectualParrafo3:
      "CCOMMS garantiza que los contenidos, incluidos los de propiedad intelectual, no son de carácter ilícito ni infringen la normativa vigente. Por consiguiente, los contenidos de la APP no tendrán carácter xenófobo, pornográfico, discriminatorio, racista o que de cualquier modo fomente la violencia.",
    termsConditionsPropiedadIntelectualParrafo4:
      "Además, CCOMMS adoptará las medidas legales que considere convenientes para impedir cualquier tipo de conducta contraria a la Ley o inmoral.",
    termsConditionsModificacionUnilateralTitulo:
      "VII.- MODIFICACIÓN UNILATERAL",
    termsConditionsModificacionUnilateralParrafo1:
      "CCOMMS podrá modificar unilateralmente y sin previo aviso, siempre que lo considere oportuno, la estructura y diseño de la APP, así como modificar o eliminar, los servicios, los contenidos y las condiciones de acceso y/o uso de la APP.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD",
    termsConditionsExclusionGarantiasParrafo1:
      "CCOMMS no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:",
    termsConditionsExclusionGarantiasListaELemento1:
      "El incumplimiento de la Ley, la moral y las buenas costumbres, así como el orden público, o el uso de los productos y/o servicios ofertados de forma no diligente o incorrecta, o con fines o efectos ilícitos o contrarios a lo establecido en las presentes CGU o en las condiciones aplicables que en su caso resulten de aplicación.",
    termsConditionsExclusionGarantiasListaELemento2:
      "Por la adquisición o contratación de los servicios, bienes o productos que puedan ser adquiridos o contratados a terceros a través de un acceso en la APP de CCOMMS, especialmente en aquellos casos en los que el proceso de compra o contratación se realiza directamente en la app del tercero y aunque aparezcan distintivos o un “frame” con los elementos gráficos de la app (Branding).",
    termsConditionsExclusionGarantiasListaELemento3:
      "La falta disponibilidad, mantenimiento y efectivo funcionamiento de la APP y/o de sus servicios o contenidos.",
    termsConditionsExclusionGarantiasListaELemento4:
      "La falta de utilidad, adecuación o validez de la APP y/o de sus servicios o contenidos para satisfacer necesidades, actividades o resultados concretos o expectativas de los usuarios.",
    termsConditionsExclusionGarantiasListaELemento5:
      "La existencia de virus, programas maliciosos o lesivos en los contenidos.",
    termsConditionsExclusionGarantiasListaELemento6:
      "La recepción, obtención, almacenamiento, difusión o transmisión, por parte de los usuarios, de los contenidos.",
    termsConditionsExclusionGarantiasListaELemento7:
      "La ilicitud o falta de veracidad, exactitud, fiabilidad, pertinencia, actualidad y exhaustividad de los contenidos e informaciones transmitidas o puestos a disposición de los usuarios, incluidos las informaciones y servicios prestados por terceros o por los usuarios a través de la APP.",
    termsConditionsExclusionGarantiasListaELemento8:
      "El incumplimiento por parte de terceros de sus obligaciones o compromisos en relación con los servicios prestados a los usuarios a través de la APP.",
    termsConditionsExclusionGarantiasListaELemento9:
      "De los contenidos, archivos, informaciones, publicidad, opiniones, conceptos e imágenes que no dependan de la APP, ni sean gestionados por CCOMMS. Tampoco se responsabiliza del mal uso que se realice de los contenidos de la APP, siendo exclusiva responsabilidad de la persona que acceda a ellos o los utilice.",
    termsConditionsExclusionGarantiasListaELemento10:
      "Del uso indebido de las calves de acceso de los usuarios para el acceso a los contenidos del servicio de la APP que los requieran y de las consecuencias derivadas de cualquier naturaleza del mal uso por los usuarios, su pérdida u olvido, y su uso indebido por terceros no autorizados.",
    termsConditionsExclusionGarantiasListaELemento11:
      "La incapacidad de cualquier usuario o la suplantación de la personalidad de un tercero efectuada por el usuario.",
    termsConditionsExclusionGarantiasListaELemento12:
      "De los contenidos facilitados por los usuarios.",
    termsConditionsProteccionDatosTitulo: "IX.-  PROTECCIÓN DE DATOS",
    termsConditionsProteccionDatosParrafo1:
      "Puede consultar la información detallada sobre Protección de Datos en nuestra página de",
    termsConditionsLeyJurisdiccionTitulo: "X.- LEY APLICABLE Y JURISDICCIÓN",
    termsConditionsLeyJurisdiccionParrafo1:
      "Todas las Condiciones, Generales y Particulares, insertadas en la presente APP se rigen por la normativa española. Todos los conflictos, controversias o situaciones derivadas de las Condiciones Generales y particulares se someterán a los Juzgados y Tribunales de la Villa de Madrid, renunciando expresamente las partes a su fuero."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Política de privacidad",
    privacyPolicieModalTitle: "POLÍTICA DE PRIVACIDAD",
    privacyPolicieModalLargeTitle: "Política de privacidad",
    privacyPoliciesConditionsParrafo1:
        "De conformidad con lo establecido tanto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD), como en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD) y demás normativa sobre protección de datos vigente, se informa a los usuarios del sitio web, que voluntariamente han manifestado su consentimiento de manera libre, clara e inequívoca para el tratamiento de los datos personales al aceptar la casilla de “Política de protección de datos”, que sus datos van a ser tratados, en función de la empresa en la con la que haya contactado, bien la empresa MAILTECK, S.A. o bien la empresa CUSTOMER COMMUNICATIONS TECKNALIA, S.L., las cuales serán denominadas conjuntamente como GRUPO MAILCOMMS dentro de esta Política de Privacidad.",
    privacyPoliciesConditionsResponsable1Title: "Responsable 1",
    privacyPoliciesConditionsResponsable2Title: "Responsable 2",
    privacyPoliciesConditionsFinalidadTitle: "Finalidad",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimacion",
    privacyPoliciesConditionsDestinatariosTitle: "Destinatarios",
    privacyPoliciesConditionsDerechosTitle: "Derechos",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Información adicional",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Política de Seguridad y Privacidad",
    privacyPoliciesConditionsIdentidadTitle: "Identidad",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Dirección",
    privacyPoliciesConditionsTelefonoTitle: "Teléfono",
    privacyPoliciesConditionsCorreoTitle: "Correo",
    privacyPoliciesConditionsDPDTitle: "Contacto con el DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "Facilitar información solicitada y de gestionar el contacto con posibles clientes.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "En GRUPO MAILCOMMS, la información que usted nos ha facilitado ha sido recabada por una de las empresas del GRUPO MAILCOMMS, con el fin de gestionar y tramitar la solicitud realizada.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, solo tratará los datos facilitados para las finalidades descritas anteriormente y no serán tratados ulteriormente de manera incompatible con dichos fines.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "Con el fin de poder ofrecerle productos y servicios de acuerdo con sus intereses y mejorar su experiencia de usuario elaboraremos un “perfil comercial”, en base a la información facilitada. No obstante, no se tomarán decisiones automatizadas en base a dicho perfil.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "Los datos personales proporcionados se conservarán mientras se mantenga la relación mercantil o no se solicite su supresión por el interesado.",
    privacyPoliciesConditionsLegitimacionIntro: "Consentimiento del interesado.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "El propio interesado facilitando sus datos a través de los canales que las empresas del GRUPO MAILCOMMS facilitan para la recabar datos de contacto (formulario de contacto, visita comercial, etc).",
    privacyPoliciesConditionsDestinatariosIntro: "Empresas colaboradoras",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Empresas colaboras y participadas para fines comerciales.</li>" +
        "<li>- Por exigencia legal cuando una norma así lo exija.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "Puede ejercer sus derechos en la forma y plazos legalmente previstos.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "Confirmación general:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Cualquier persona tiene derecho a obtener confirmación sobre si GRUPO MAILCOMMS se están tratando datos personales que les conciernan.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "Derechos ARCO:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitación del tratamiento:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Oposición al tratamiento:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos.\n" +
        "Por lo tanto, GRUPO MAILCOMMS dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Portabilidad de los datos:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán solicitar recibir los datos personales facilitados en el formulario de contacto, en un formato estructurado, de uso común y legible por máquina, así como transmitir dichos datos a otro responsable del tratamiento sin impedimentos.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Reclamación ante organismo público:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "En caso de considerar vulnerado su derecho a la protección de datos personales, podrá interponer una reclamación ante la Agencia Española de Protección de Datos (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Canal para ejercitar los derechos:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Los interesados podrán ejercer los derechos que les reconoce en la normativa frente a, y en contra de, cada uno de los responsables, ante las direcciones habilitada como DPO en el apartado «Responsable».',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "Más información sobre Protección de Datos.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Los datos personales se han obtenido de los canales que las empresas del CCMS facilitan para la recabar datos de contacto (formulario de contacto, visita comercial, etc).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "Las categorías de datos que se van a tratan son datos de identificación (nombre, apellidos, correo electrónico, etc).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS no trata datos especialmente protegidos.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "Grupo MailComms, Puede consultar la Política de Seguridad y Privacidad basada en la norma ISO 27001",
    privacyPoliciesConditionsPoliticaInformacionContent:
      "<div class=\"datos-content\">\n" +
      "  Versión: 8 | Fecha: 26 de noviembre 2024<br /><br />\n" +
      "\n" +
      "  <p>\n" +
      "    <strong\n" +
      "      >ANEXO I: POLÍTICA DEL SISTEMA DE GESTIÓN DE SEGURIDAD, CONTINUIDAD Y\n" +
      "      PRIVACIDAD DE LA INFORMACIÓN</strong\n" +
      "    >\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Esta Política de Seguridad, Continuidad y Privacidad de la Información\n" +
      "    muestra el compromiso de la Dirección dotando a la organización de recursos,\n" +
      "    responsabilidades y procedimientos que permitan garantizar el cumplimiento\n" +
      "    de la normativa vigente, así como la integridad, confidencialidad y\n" +
      "    disponibilidad de la información y los sistemas, siendo crucial para la\n" +
      "    seguridad, privacidad y continuidad de nuestra organización, así como la de\n" +
      "    nuestros clientes.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Grupo MailComms, consciente de que la seguridad, continuidad y privacidad de\n" +
      "    la información relativa a nuestros clientes es un recurso con gran valor, ha\n" +
      "    establecido un Sistema de Gestión de la Seguridad, Continuidad y Privacidad\n" +
      "    de la Información de acuerdo con los requisitos de las normas ISO/IEC 27001,\n" +
      "    ISO/IEC 27701e ISO 22301 para garantizar la continuidad de los sistemas de\n" +
      "    información, minimizar los riesgos de daño y asegurar el cumplimiento de los\n" +
      "    objetivos fijados.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    De manera adicional, para el servicio de Entrega Electrónica Certificada, en\n" +
      "    base a la naturaleza del propio servicio prestado, se han seguido las\n" +
      "    disposiciones establecidas en el Reglamento Europeo eIDAS que regula los\n" +
      "    Servicios de Confianza, y concretamente las normas ETSI 319 401 y ETSI 319\n" +
      "    521 para Proveedores de Servicios de Entrega Electrónica Certificada que\n" +
      "    contienen preceptos específicos para la prestación de este tipo de servicio.\n" +
      "    En este sentido, la Política que regirá este servició será la Declaración de\n" +
      "    Prácticas del Servicio de Confianza de Entrega Electrónica Certificada (en\n" +
      "    adelante “DPC”).\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Asimismo, en cumplimiento de la normativa europea de ciberseguridad, las\n" +
      "    entidades que forman parte del Grupo, en su condición de Prestadores\n" +
      "    Cualificados de Servicios de Confianza, se comprometen a cumplir con la\n" +
      "    Directiva (UE) 2022/2555 relativa a medidas para garantizar un nivel elevado\n" +
      "    común de ciberseguridad en toda la Unión, como entidades esenciales de la\n" +
      "    infraestructura digital europea.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    El objetivo de la Política de Seguridad, Continuidad y Privacidad es fijar\n" +
      "    el marco de actuación necesario para proteger los recursos de información\n" +
      "    frente a amenazas, internas o externas, deliberadas o accidentales, con el\n" +
      "    fin de asegurar el cumplimiento de la confidencialidad, integridad y\n" +
      "    disponibilidad de la información.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    La eficacia y aplicación del Sistema de Gestión de la Seguridad, Continuidad\n" +
      "    y Privacidad de la Información es responsabilidad directa del Comité de la\n" +
      "    Seguridad y Privacidad de la Información, el cual es responsable de la\n" +
      "    aprobación, difusión y cumplimiento de la presente Política de Seguridad,\n" +
      "    Continuidad y Privacidad. En su nombre y representación se ha nombrado un\n" +
      "    Responsable del Sistema de Gestión de la Seguridad y Privacidad de la\n" +
      "    Información, que posee la suficiente autoridad para desempeñar un papel\n" +
      "    activo en el Sistema de Gestión de la Seguridad y Privacidad de la\n" +
      "    Información, supervisando su implantación, desarrollo y mantenimiento.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Adicionalmente, y para dar cumplimiento a la normativa vigente de protección\n" +
      "    de datos, se ha nombrado un Delegado de Protección de datos (DPO) con el\n" +
      "    objeto de garantizar la seguridad y privacidad de los datos de carácter\n" +
      "    personal, especialmente teniendo en cuenta la actividad de la compañía.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    El Comité de Seguridad y Privacidad de la Información procederá a\n" +
      "    desarrollar y aprobar la metodología de análisis de riesgos utilizada en el\n" +
      "    Sistema de Gestión de la Seguridad, Continuidad y Privacidad de la\n" +
      "    Información.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Toda persona cuya actividad pueda, directa o indirectamente, verse afectada\n" +
      "    por los requisitos del Sistema de Gestión de la Seguridad, Continuidad y\n" +
      "    Privacidad de la Información, está obligada al cumplimiento estricto de la\n" +
      "    Política de Seguridad, Continuidad y Privacidad y adicionalmente, para el\n" +
      "    Servicio de Entrega Electrónica Certificada, deberá cumplir con la DPC.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    En Grupo MailComms se implantarán todas las medidas necesarias para cumplir\n" +
      "    la normativa aplicable en materia de seguridad, continuidad y privacidad en\n" +
      "    general y de seguridad informática, relativa a la política informática, a la\n" +
      "    seguridad de edificios e instalaciones y al comportamiento de empleados y\n" +
      "    terceras personas asociadas con Grupo MailComms en el uso de sistemas\n" +
      "    informáticos y tratamiento de datos personales. Las medidas necesarias para\n" +
      "    garantizar la seguridad y privacidad de la información mediante la\n" +
      "    aplicación de normas, procedimientos y controles deberán permitir asegurar\n" +
      "    la confidencialidad, integridad, disponibilidad de la información,\n" +
      "    esenciales para:\n" +
      "  </p>\n" +
      "  <ul style='list-style: disc;'>\n" +
      "    <li>\n" +
      "      Cumplir con la legislación, reglamentación y normativa vigente en materia\n" +
      "      de continuidad, privacidad y de los sistemas de información y con todos\n" +
      "      los requisitos contractuales en materia de protección de datos y servicios\n" +
      "      electrónicos de confianza, así como los que considere oportuno llevar a\n" +
      "      cabo con el objetivo de lograr una mejora continua de la organización y\n" +
      "      del sistema de gestión de seguridad, continuidad y privacidad.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Asegurar la confidencialidad de los datos gestionados por Grupo MailComms.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Asegurar la disponibilidad de los sistemas de información, tanto en los\n" +
      "      servicios ofrecidos a los clientes como en la gestión interna.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Asegurar la capacidad de respuesta ante situaciones de emergencia,\n" +
      "      restableciendo el funcionamiento de los servicios críticos en el menor\n" +
      "      tiempo posible.\n" +
      "    </li>\n" +
      "    <li>Evitar alteraciones indebidas en la información.</li>\n" +
      "    <li>\n" +
      "      Promover la concienciación y formación en seguridad, continuidad y\n" +
      "      privacidad de la información y en materia de servicios electrónicos de\n" +
      "      confianza.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Impulsar y participar en conseguir la mejora continua del sistema de\n" +
      "      gestión de la seguridad continuidad y privacidad de la información y\n" +
      "      servicios electrónicos de confianza.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Establecer requisitos técnicos y metodológicos de las medidas de gestión\n" +
      "      de riesgos en materia de ciberseguridad, identificando los riesgos y\n" +
      "      corrigiendo las vulnerabilidades detectadas, ciberamenazas e incidentes de\n" +
      "      ciberseguridad, a fin de evitar la materialización de incidentes que\n" +
      "      comprometan la continuidad de negocio de Grupo MailComms.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Actualizar los resultados de la evaluación de riesgos y el plan de\n" +
      "      tratamiento de riesgos a intervalos planificados y al menos una vez al\n" +
      "      año, y cuando se produzcan cambios significativos en las operaciones o los\n" +
      "      riesgos o incidentes significativos.\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    Las Políticas para la Seguridad y Privacidad de la Información y la DPC\n" +
      "    deben revisarse a intervalos planificados, como mínimo anualmente y siempre\n" +
      "    que se produzcan cambios significativos en la organización, a fin de\n" +
      "    asegurar que se mantenga su idoneidad, adecuación y eficacia.\n" +
      "  </p>\n" +
      "</div>",
    privacyPoliciesConditionsPoliticaRedesTitle: "Política de Seguridad de Redes y Sistemas de Información",
    privacyPoliciesConditionsPoliticaRedesSubtitle: "Grupo MailComms, Puede consultar Política de Seguridad de Redes y Sistemas de Información",
    privacyPoliciesConditionsPoliticaRedesContent: "<div class=\"datos-content\">\n" +
      "  Versión: 4 | Fecha: 4 de diciembre 2024<br /><br />\n" +
      "\n" +
      "  <p>\n" +
      "    <strong\n" +
      "      >ANEXO 2: POLÍTICA DE SEGURIDAD DE REDES Y SISTEMAS DE INFORMACIÓN</strong\n" +
      "    >\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    La dirección de <strong>Grupo MailComms</strong> (formado por MAILTECK S.A Y\n" +
      "    CUSTOMER COMMUNICATIONS TECKNALIA S.L.), en adelante “Grupo MailComms” o, de\n" +
      "    forma específica, “Mailteck” y/o “CCOMMS”, consciente de la necesidad de\n" +
      "    promover, mantener y mejorar el enfoque hacia el cliente en todas sus\n" +
      "    actividades, ha implantado un Sistema de Gestión Integrado (SGI) conforme a\n" +
      "    varios estándares reconocidos internacionalmente. El objetivo final del SGI\n" +
      "    es asegurar que entendemos y compartimos las necesidades y metas de nuestros\n" +
      "    clientes, intentando prestar servicios que cumplan sus expectativas y\n" +
      "    trabajando en la mejora continua. Grupo MailComms manifiesta expresamente su\n" +
      "    compromiso de potenciar la\n" +
      "    <strong>Seguridad y Ciberseguridad de la Información</strong>, en concreto,\n" +
      "    de garantizar, entre otros, la seguridad de las redes y los sistemas de\n" +
      "    información en los que se apoyen los diferentes procesos de negocio de tal\n" +
      "    manera que refuerce su resiliencia operativa digital, así como, la\n" +
      "    <strong>continuidad y disponibilidad</strong> del servicio prestado\n" +
      "    alineando sus prácticas con la normativa vigente que resulte de aplicación.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    En este sentido, Grupo MailComms se compromete a desarrollar las máximas\n" +
      "    capacidades en materia de ciberseguridad, reduciendo así las amenazas para\n" +
      "    los sistemas de red y de información utilizados por Grupo MailComms conforme\n" +
      "    a la normativa europea de ciberseguridad.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Al mismo tiempo se compromete a satisfacer las necesidades y expectativas de\n" +
      "    las partes interesadas, a mantener alta la competitividad en los servicios y\n" +
      "    productos para el\n" +
      "    <strong\n" +
      "      >“Tratamiento automatizado de comunicaciones y transacciones multicanal\n" +
      "      con clientes, Emisión de documentos en formato electrónico y en papel, así\n" +
      "      como Certificación electrónica de documentos de seguridad y Servicios de\n" +
      "      confianza: Servicio Cualificado de Entrega Electrónica Certificada,\n" +
      "      Contratación y Firma electrónica; Notificaciones y Comunicaciones\n" +
      "      electrónicas y postales; Identificación y Autenticación electrónica y\n" +
      "      Archivo digital”.</strong\n" +
      "    >\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    <strong>ROL DE GRUPO MAILCOMMS FRENTE A LA DIRECTIVA (UE) 2022/2555</strong>\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    MAILTECK y CCOMMS, en su condición de Prestadores Cualificados de Servicios\n" +
      "    de Confianza, se consideran entidades esenciales de la infraestructura\n" +
      "    digital europea de conformidad con la Directiva (UE) 2022/2555 relativa a\n" +
      "    las medidas destinadas a garantizar un elevado nivel común de ciberseguridad\n" +
      "    en toda la Unión, denominada comúnmente como “Directiva NIS 2”, lo que\n" +
      "    conlleva su condición de sujeto obligado.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Es por eso, que la presente Política se integra en la estrategia de\n" +
      "    seguridad que persigue Grupo MailComms, con la finalidad de que el uso de\n" +
      "    las redes y sistemas de información responda al respecto del derecho de las\n" +
      "    partes interesadas y a la salvaguarda de los más altos estándares de\n" +
      "    ciberseguridad, teniendo en cuenta las actividades y estructura del Grupo.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Por tanto, Grupo MailComms pasará a ser responsable del cumplimiento de las\n" +
      "    obligaciones exigidas por esta normativa.\n" +
      "  </p>\n" +
      "  <p><strong>MISIÓN y OBJETIVOS: </strong></p>\n" +
      "  <ul>\n" +
      "    <li>Fomentar la mejora continua de los servicios y soporte al cliente.</li>\n" +
      "    <li>\n" +
      "      Continuar el posicionamiento de <strong>Grupo MailComms</strong> como\n" +
      "      referente en el sector.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Implantar, mantener y comprobar nuestros mecanismos de continuidad de la\n" +
      "      actividad para garantizar que la información y los servicios vitales estén\n" +
      "      a disposición de nuestros clientes cuando sea necesario.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Proporcionar a los clientes el equipo más profesional y disponer de forma\n" +
      "      inmediata, y durante el tiempo necesario, de técnicos altamente\n" +
      "      cualificados, expertos en las disciplinas requeridas y acostumbrados a\n" +
      "      trabajar en equipo.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Facilitar los recursos adecuados necesarios para aplicar la presente\n" +
      "      Política, incluidos el personal, los recursos financieros, los procesos,\n" +
      "      las herramientas y las tecnologías necesarias.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Tener una prestación del servicio basada en nuestro compromiso con la\n" +
      "      mejora continua de nuestros sistemas, con la seguridad, la ciberseguridad\n" +
      "      y la continuidad de la información como pilar central.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Impulsar y promocionar una cultura de ciberseguridad entre todos sus\n" +
      "      profesionales y sujetos obligados por esta Política, ya sea internamente,\n" +
      "      o entre sus clientes y proveedores.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Implantar una cultura de seguridad de la información mediante la formación\n" +
      "      y la sensibilización.\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    <strong>Nuestra misión y objetivos los conseguiremos a través de:</strong>\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>\n" +
      "      Un sistema de objetivos, métricas e indicadores de mejora continua,\n" +
      "      seguimiento, medición de nuestros procesos internos, así como de la\n" +
      "      satisfacción de nuestros clientes. Estableciendo y supervisando el\n" +
      "      cumplimiento de los requisitos contractuales para asegurar un servicio\n" +
      "      eficaz y seguro.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Integrar dentro del sistema de métricas del SGSI indicadores vinculados al\n" +
      "      nivel de implantación y de madurez de las medidas de seguridad.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Formando y concienciando continuamente a nuestro equipo para tener el\n" +
      "      mayor grado de profesionalidad y especialización posible.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Teniendo nuestras infraestructuras en un estado adecuado y en concordancia\n" +
      "      con los requerimientos de nuestros clientes.\n" +
      "    </li>\n" +
      "    <li>Organización e implantación de los procesos de seguridad.</li>\n" +
      "    <li>Gestión del personal.</li>\n" +
      "    <li>Autorización y control de accesos.</li>\n" +
      "    <li>Seguridad por defecto.</li>\n" +
      "    <li>Integridad y actualización del sistema.</li>\n" +
      "    <li>Protección de la información almacenada y en tránsito.</li>\n" +
      "    <li>Prevención ante otros sistemas de información interconectados.</li>\n" +
      "    <li>\n" +
      "      Implementando un procedimiento seguro de gestión de adquisición de\n" +
      "      productos.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Cumpliendo las exigencias de la legislación vigente, especialmente en\n" +
      "      materia de Protección de Datos de Carácter Personal: GDPR y LOPDGDD.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Cumpliendo con las Políticas y Procedimientos definidos en nuestra\n" +
      "      Documentación de Seguridad.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Introduciendo los procesos de mejora continua que permitan un avance\n" +
      "      permanente en nuestra gestión de Seguridad de la Información.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Gestionando y elaborando planes para la gestión y tratamiento de los\n" +
      "      riesgos con una metodología de análisis y gestión de riesgos utilizada,\n" +
      "      basada en estándares.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Gestionando de manera segura las comunicaciones internas y externas, así\n" +
      "      como la información almacenada y en tránsito.\n" +
      "    </li>\n" +
      "    <li>Asegurando la interconexión con otros sistemas de información.</li>\n" +
      "    <li>Gestionando y monitorizando la actividad con la gestión de logs.</li>\n" +
      "    <li>\n" +
      "      Gestionando diariamente medidas encaminadas a la protección y seguridad de\n" +
      "      las redes y sistemas de información, diseñando medidas de seguridad\n" +
      "      robustas, alineadas con las necesidades de las diferentes partes\n" +
      "      interesadas, así como con la normativa vigente aplicable en la materia,\n" +
      "      para lo cual, Grupo MailComms aprueba las políticas y/o procedimientos\n" +
      "      específicos que desarrollan los principios y requisitos básicos de\n" +
      "      seguridad de las redes y sistemas de información establecidos en la\n" +
      "      presente Política. El objetivo es identificar los riesgos y corregir las\n" +
      "      vulnerabilidades detectadas, ciberamenazas e incidentes de ciberseguridad,\n" +
      "      a fin de evitar la materialización de incidentes que comprometan la\n" +
      "      continuidad de negocio de Grupo MailComms.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Definiendo políticas y procedimientos para la gestión de incidentes de\n" +
      "      seguridad.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Asegurando la continuidad y disponibilidad del negocio y de los servicios.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Asegurando que nuestros Activos y Servicios cumplen con las medidas del\n" +
      "      ENS de Nivel ALTO para las dimensiones de Confidencialidad, Integridad,\n" +
      "      Disponibilidad, Autenticidad y Trazabilidad.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Estableciendo, implantando y aplicando procedimientos y/o políticas de\n" +
      "      seguridad en la cadena de suministro que rijan las relaciones con los\n" +
      "      proveedores directos y prestadores de servicios y establezca las medidas y\n" +
      "      controles oportunos con el fin de mitigar los riesgos detectados derivados\n" +
      "      de estos terceros para la seguridad de las redes y los sistemas de\n" +
      "      información del Grupo.\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    Además, estos principios se deberán contemplar en las siguientes\n" +
      "    <strong>áreas de seguridad:</strong>\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>\n" +
      "      <strong>Física:</strong> Comprendiendo la seguridad de las dependencias,\n" +
      "      instalaciones, sistemas hardware, soportes y cualquier activo de\n" +
      "      naturaleza física que trate o pueda tratar información, así como los\n" +
      "      accesos físicos.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      <strong>Lógica:</strong> Incluyendo los aspectos de protección de\n" +
      "      aplicaciones, redes, comunicación electrónica, sistemas informáticos y con\n" +
      "      los accesos lógicos.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      <strong>Político-corporativa:</strong> Formada por los aspectos de\n" +
      "      seguridad relativos a la propia organización, a las normas internas,\n" +
      "      regulaciones y normativa legal.\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    El objetivo último de la seguridad de la información es asegurar que una\n" +
      "    organización pueda cumplir sus objetivos utilizando sistemas de información.\n" +
      "    En las decisiones en materia de seguridad deberán tenerse en cuenta los\n" +
      "    siguientes principios básicos:\n" +
      "  </p>\n" +
      "  <ol style=\"list-style-type: lower-alpha\">\n" +
      "    <li>Organización e implantación del proceso de seguridad.</li>\n" +
      "    <li>Análisis y gestión de los riesgos.</li>\n" +
      "    <li>Gestión de personal.</li>\n" +
      "    <li>Profesionalidad.</li>\n" +
      "    <li>Autorización y control de los accesos.</li>\n" +
      "    <li>Protección de las instalaciones.</li>\n" +
      "    <li>\n" +
      "      Adquisición de productos de seguridad y contratación de servicios de\n" +
      "      seguridad.\n" +
      "    </li>\n" +
      "    <li>Mínimo privilegio.</li>\n" +
      "    <li>Integridad y actualización del sistema.</li>\n" +
      "    <li>Protección de la información almacenada y en tránsito.</li>\n" +
      "    <li>Prevención ante otros sistemas de información interconectados.</li>\n" +
      "    <li>Registro de la actividad y detección de código dañino.</li>\n" +
      "    <li>Incidentes de seguridad.</li>\n" +
      "    <li>Continuidad de la actividad.</li>\n" +
      "    <li>Mejora continua del proceso de seguridad.</li>\n" +
      "  </ol>\n" +
      "  <p>\n" +
      "    Toda la documentación de ciberseguridad que se desarrolle en ejecución de\n" +
      "    los requisitos establecidos en este punto se gestiona, estructura y conserva\n" +
      "    conforme a los procedimientos documentados que Grupo MailComms ha\n" +
      "    desarrollado teniendo en cuenta los estándares y normas técnicas nacionales\n" +
      "    e internacionales que apliquen en cada caso. Se ha establecido un plazo\n" +
      "    mínimo de conservación del marco normativo y de las evidencias que sustentan\n" +
      "    el cumplimiento del mismo en materia de ciberseguridad de 5 años. En el\n" +
      "    marco del Servicio de Entrega Electrónica Cualificada, las Declaraciones de\n" +
      "    prácticas (DPCs) y la Declaración final del servicio, se conservarán durante\n" +
      "    15 años.\n" +
      "  </p>\n" +
      "  <p><strong>Roles o funciones de seguridad en el marco del ENS:</strong></p>\n" +
      "  <p>\n" +
      "    <u>Responsable de la Información</u>: determinará los requisitos (de\n" +
      "    seguridad) de la información tratada, según los parámetros del Anexo I del\n" +
      "    ENS.\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>\n" +
      "      Tiene la responsabilidad última del uso que se haga de la información y,\n" +
      "      por tanto, de su protección. Es el responsable último de cualquier error o\n" +
      "      negligencia que conlleve un incidente de confidencialidad o de integridad\n" +
      "      (en materia de protección de datos) y de disponibilidad (en materia de\n" +
      "      seguridad de la información).\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    <u>Responsable del sistema</u>: Determina los requisitos de los servicios\n" +
      "    prestados.\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>\n" +
      "      Tiene las siguientes funciones:\n" +
      "      <ul>\n" +
      "        <li>\n" +
      "          Desarrollar, operar y mantener el sistema de información durante todo\n" +
      "          su ciclo de vida, incluyendo sus especificaciones, instalación y\n" +
      "          verificación de su correcto funcionamiento.\n" +
      "        </li>\n" +
      "        <li>\n" +
      "          Definir la topología y la gestión del sistema de información,\n" +
      "          estableciendo los criterios de uso y los servicios disponibles en el\n" +
      "          mismo.\n" +
      "        </li>\n" +
      "        <li>\n" +
      "          Cerciorarse de que las medidas de seguridad se integren adecuadamente\n" +
      "          en el marco general de seguridad.\n" +
      "        </li>\n" +
      "      </ul>\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    <u>Responsable de Seguridad de la Información</u>: Determina las decisiones\n" +
      "    para satisfacer los requisitos de seguridad de la información y de los\n" +
      "    servicios.\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>\n" +
      "      Las dos funciones esenciales del Responsable de la Seguridad son:\n" +
      "      <ul>\n" +
      "        <li>\n" +
      "          Mantener la seguridad de la información manejada y de los servicios\n" +
      "          prestados por los sistemas de información en su ámbito de\n" +
      "          responsabilidad, de acuerdo con lo establecido en la Política de\n" +
      "          Seguridad de la Información de la organización.\n" +
      "        </li>\n" +
      "        <li>\n" +
      "          Promover la formación y concienciación en materia de seguridad de la\n" +
      "          información dentro de su ámbito de responsabilidad.\n" +
      "        </li>\n" +
      "      </ul>\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    <u>Responsable del Servicio</u>: Determina los requisitos (de seguridad) de\n" +
      "    los servicios prestados, según los parámetros del Anexo I del ENS.\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>\n" +
      "      Tiene la potestad de establecer los requisitos del servicio en materia de\n" +
      "      seguridad. La determinación de los niveles en cada dimensión de seguridad\n" +
      "      se realiza dentro del marco establecido en el Anexo I del Esquema Nacional\n" +
      "      de Seguridad estando los criterios de valoración respaldados por la\n" +
      "      Política de Seguridad.\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    <u>El Comité de Seguridad y privacidad de la Información</u> (CSPI) de\n" +
      "    <strong>Grupo MailComms</strong>, con la finalidad de regular y\n" +
      "    procedimentar las medidas y políticas de seguridad y privacidad de la\n" +
      "    información, así como de las normativas orientadas a la adaptación de los\n" +
      "    sistemas de información a la normativa vigente de protección de datos de\n" +
      "    carácter personal., tiene entre otras funciones:\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>Nombrar al Responsable del SGSPI y aprobar sus funciones.</li>\n" +
      "    <li>Aprobar el inicio de la implantación del SGSPI.</li>\n" +
      "    <li>Revisión y aprobación de la Política de Seguridad y Privacidad.</li>\n" +
      "    <li>\n" +
      "      Aprobación de la Documentación del Sistema de Seguridad y Privacidad de la\n" +
      "      Información, así como de nuevas ediciones o modificaciones.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Seguimiento de la implantación y funcionamiento del Sistema de Seguridad y\n" +
      "      Privacidad de la Información.\n" +
      "    </li>\n" +
      "    <li>Análisis de reclamaciones planteadas por los clientes</li>\n" +
      "    <li>\n" +
      "      Evaluar de forma periódica el grado de exposición a riesgos que afecten a\n" +
      "      los sistemas de información y tratamientos de datos personales de Grupo\n" +
      "      Mailcomms.\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p><u>Componen el CSPI</u>:</p>\n" +
      "  <ul>\n" +
      "    <li>Director General.</li>\n" +
      "    <li>Director Tecnología.</li>\n" +
      "    <li>Director de Operaciones IT.</li>\n" +
      "    <li>Director de Operaciones Printing</li>\n" +
      "    <li>Director Financiero, RRHH y Jurídico</li>\n" +
      "    <li>Director Comercial MailComms</li>\n" +
      "    <li>Director de Técnico</li>\n" +
      "    <li>Director de Marketing</li>\n" +
      "    <li>Director de Mercado Internacional</li>\n" +
      "    <li>Responsable del SGSPI</li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    Considerando estas pautas, esta dirección reitera su más firme compromiso\n" +
      "    aunando esfuerzos para el logro de estos objetivos, por lo que esta política\n" +
      "    es entendida, implantada y mantenida al día en todos los niveles de la\n" +
      "    organización.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    <strong>Estructuración de la documentación de seguridad del sistema</strong>\n" +
      "  </p>\n" +
      "  <p>La documentación del sistema sigue la siguiente estructura:</p>\n" +
      "\n" +
      "    <img\n" +
      "     style=\"margin:0 auto;\"\n" +
      "      src=\"/anexo2_img01_spa.jpg\"\n" +
      "      alt=\"Estructura de la documentación\"\n" +
      "    />\n" +
      "  <p>\n" +
      "    La clasificación de la información del sistema se clasifica en las\n" +
      "    siguientes categorías, tal y como se establece en los procedimientos\n" +
      "    PE-SGSPI 01-ERDS_Elaboración y control de la Documentación:\n" +
      "  </p>\n" +
      "  <ul>\n" +
      "    <li>Uso Público</li>\n" +
      "    <li>Uso Interno</li>\n" +
      "    <li>Confidencial</li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    <strong\n" +
      "      >Legislación aplicable en materia de tratamiento de datos de carácter\n" +
      "      personal</strong\n" +
      "    >\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    En materia de tratamiento de datos de carácter personal se tendrá en cuenta,\n" +
      "    principalmente, el Reglamento (UE) 2016/679 del Parlamento Europeo y del\n" +
      "    Consejo, de 27 de abril de 2016, relativo a la protección de las personas\n" +
      "    físicas en lo que respecta al tratamiento de datos personales y a la libre\n" +
      "    circulación de estos datos y por el que se deroga la Directiva 95/46/CE y la\n" +
      "    legislación nacional correspondiente.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    El marco legal y regulatorio aplicable se encuentran recogido en el\n" +
      "    documento RE01-PE_SGSPI18 Normativa y Legislación Aplicable\n" +
      "  </p>\n" +
      "  <p><strong>Seguimiento, aprobación, publicación y revisión </strong></p>\n" +
      "  <p>\n" +
      "    El CSPI debe aprobar las Políticas de Seguridad, Ciberseguridad, Continuidad\n" +
      "    y Privacidad de la entidad, publicarlas y/o distribuirlas a todos sus\n" +
      "    empleados, así como a terceras partes que puedan verse involucrados en la\n" +
      "    implementación de los sistemas de información.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    El responsable del SGSPI, y el responsable del Servicio correspondiente, en\n" +
      "    lo que respecta a las políticas específicas de cada servicio, se encargarán\n" +
      "    de hacerlas públicas o, en su caso, comunicarlas y mantenerlas siempre en\n" +
      "    los sistemas de Grupo MailComms.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    Las Políticas para la Seguridad, Ciberseguridad, Continuidad y Privacidad de\n" +
      "    la Información y las políticas específicas de cada servicio deben revisarse\n" +
      "    a intervalos planificados, como mínimo anualmente y siempre que se produzcan\n" +
      "    cambios significativos en la organización, a fin de asegurar que se mantenga\n" +
      "    su idoneidad, adecuación y eficacia.\n" +
      "  </p>\n" +
      "  <p>\n" +
      "    El listado de Políticas aplicables en el marco del ENS y SGSPI-ERDS se\n" +
      "    encuentra en el documento “RE01-PE SGSPI-ERDS 01 Listado de documentos y\n" +
      "    registros del SGSPI-ERDS”.\n" +
      "  </p>\n" +
      "  <p>Por otro lado, el listado de Políticas aplicables en el marco de ERDS:</p>\n" +
      "  <ul>\n" +
      "    <li>\n" +
      "      Declaración de Prácticas del Servicio de Confianza de Entrega Electrónica\n" +
      "      Certificada de Mailteck.\n" +
      "    </li>\n" +
      "    <li>\n" +
      "      Declaración de Prácticas del Servicio de Confianza de Entrega Electrónica\n" +
      "      Certificada de Customer Comms.\n" +
      "    </li>\n" +
      "    <li>Anexo I Política SGSPI-ERDS.</li>\n" +
      "  </ul>\n" +
      "  <p>\n" +
      "    <sup>1 </sup>Directiva (UE) 2022/2555 del Parlamento Europeo y del Consejo, de 14 de diciembre de 2022, relativa a medidas para un nivel elevado común de ciberseguridad en toda la Unión, por la que se modifican el Reglamento (UE) n.º 910/2014 y la Directiva (UE) 2018/1972 y se deroga la Directiva 2016/1148. \n" +
      "  </p>\n" +
      "  <p style=\"margin:0 auto;\" class=\"trext-center\">\n" +
      "    MARCO LEGAL: Real Decreto 311/2022, de 3 de mayo, de modificación del Real\n" +
      "    Decreto 3/2010, de 8 de enero, por el que se regula el Esquema Nacional de\n" +
      "    Seguridad en el ámbito de la Administración Electrónica\n" +
      "  </div>\n" +
      "</div>",
},
  cookies: {
    cookiesPoliciesCompliance: "Política de Cookies",
    cookiesPoliciesIntroText:
      "Este sitio web utiliza cookies para mejorar su experiencia mientras navega pemor el sitio web. Puede cambiar estos ajustes en cualquier momento. Las cookies clasificadas como necesarias se almacenan en su navegador y son esenciales para el funcionamiento de las funcionalidades básicas del sitio web. Las cookies de terceros se almacenarán en su navegador solo con su consentimiento y nos ayudan a analizar y comprender cómo se utiliza este sitio web. Tiene la opción de optar por no recibir estas cookies, sin embargo, la exclusión voluntaria de alguna de esta cookies puede hacer que algunas funciones dejen de estar disponibles. Para obtener información sobre eliminar las cookies, por favor consulte la función de ayuda de su navegador.",
    cookiesPoliciesNecessaryCookies: "Cookies necesarias",
    cookiesPoliciesNecessaryCookiesText:
      "Este tipo de cookies son absolutamente esenciales para que el sitio web funcione correctamente. Esta categoría solo incluye cookies que garantizan funcionalidades básicas y características de seguridad del sitio web, y no recopilan o almacenan ninguna información personal sobre los usuarios del sitio web.",
    cookiesPoliciesUnnecessaryCookies: "Cookies no necesarias",
    cookiesPoliciesUnnecessaryCookiesText:
      "Las cookies que pueden no ser particularmente necesarias para que el sitio web funcione y se utilizan específicamente para recopilar datos personales del usuario a través de análisis, anuncios y otros contenidos integrados se denominan cookies no necesarias. Es obligatorio obtener el consentimiento del usuario antes de ejecutar estas cookies en su sitio web.",
    cookiesPoliciesGoogleAnalyticsCookies: "Cookies de Google Analytics",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "Para obtener más información respecto a este tipo de cookies, puede obtener más información consultando el documento de “Uso de las cookies de Google Analytics en sitios web",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Mantener cookies activas",
    cookiesNotificationBarText:
      "Utilizamos cookies, tanto propias como de terceros, para medir las visitas, garantizar la funcionalidad del sitio web y analizar la navegación en el mismo. A continuación puede aceptar las cookies o configurar/rechazar el uso de las mismas. Puede obtener más información sobre la titularidad de la web, responsable del tratamiento de las cookies y de sus datos de contacto en nuestra",
    cookiesNotificationBarConfig: "Configurar",
    cookiesPoliciesModalIntro: "Introducción",
    cookiesPoliciesModalIntroP1:
      "Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas para mejorar y optimizar la experiencia del usuario.",
    cookiesPoliciesModalIntroP2:
      "En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocer como usuario, obtener información sobre hábitos de navegación, o personalizar la forma en que se muestra el contenido.",
    cookiesPoliciesModalIntroP3:
      "De conformidad con el Real Decreto Ley 13/2012 y su afectación en el artículo 22.2 de la Ley de Servicios de la Sociedad de la Información y Comercio Electrónico, mediante la presente Política se le informa de las diferentes cookies que CUSTOMER COMMUNICATIONS TECKNALIA, S.L. recaba a través de los distintos medios o canales de comunicación, propias o de terceros, exentas o no del deber de informar y los diferentes medios a través de los cuales puede rechazar, permitir o bloquear aquellas.",
    cookiesPoliciesModalIntroP4:
      "A continuación se detalla información sobre: qué son las “Cookies”, qué tipología utiliza este sitio web, cómo pueden ser desactivarlas en un navegador y cómo bloquear específicamente la instalación de Cookies de terceros.",
    cookiesPoliciesModalResponsibility:
      "¿Quién es el responsable del uso de las cookies en el presente sitio web?",
    cookiesPoliciesModalResponsibilityP1:
      "El responsable del uso de la cookies es CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en adelante CCOMMS), con N.I.F. número B-86414000 y con domicilio social en Leganés (Madrid) Avenida de la Recomba, 14, debidamente inscrita en el Registro Mercantil de Madrid al Tomo 29.801, Folio 98, Sección 8ª, Hoja número M-536210.",
    cookiesPoliciesModalResponsibilityP2:
      "Correo electrónico del Delegado de Protección de Datos.",
    cookiesPoliciesModalWhatIsCookie: "¿Qué es una cookie?",
    cookiesPoliciesModalWhatIsCookieP1:
      "Una cookie es un dato, fichero o dispositivo de almacenamiento y recuperación de datos (DARD), que un navegador web almacena de forma automática en el dispositivo (Smartphone, tableta o televisión conectada) de un usuario al acceder a páginas web, cuentas de redes sociales o al recibir un correo electrónico.",
    cookiesPoliciesModalWhatIsCookieP2:
      "Las cookies permiten almacenar y recuperar de forma automatizada datos sobre el uso que se ha hecho de los citados medios digitales y del equipo o dispositivo desde el que accedes a los mismos.",
    cookiesPoliciesModalWhatIsCookieP3:
      "Una cookie son sólo datos que se almacenan en el ordenador del usuario, por lo que no son software, tampoco son fragmentos de código, son simplemente datos. Por tanto, en principio las cookies no pueden transmitir y ejecutar virus, ni instalar malware como troyanos o programas de espionaje.",
    cookiesPoliciesModalCookieTypes:
      "Qué tipo de cookies hay en la página web y su finalidad",
    cookiesPoliciesModalCookiesInstall:
      "¿Qué cookies se pueden instalar en la web de Verificación de Certificados?",
    cookiesPoliciesModalCookiesInstallP1:
      "El acceso de Web de Verificación de Certificados implica que se puedan instalar las Cookies, propios y/o de tercero, que se detallan en el siguiente cuadro:",
    cookiesPoliciesModalBrowserConfigCookies:
      "Cómo configurar las cookies en el navegador",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      "Usted tiene la opción de no recibir cookies o de ser informado acerca de su fijación mediante la configuración de su navegador a tal efecto, todas las cookies, salvo las de Flash, puede ser gestionadas desde su navegador, normalmente en la sección “Opciones” o “Preferencias”.",
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "Puede desactivarlas del todo, en cuyo caso su navegador no permitirá que se instale ninguna (imposibilitando que pueda hacer uso de todas las funcionalidades de la página web), o sólo las que provenga de determinadas webs, lo que le permitirá poder disfrutar de las funcionalidades avanzadas de las webs que respetan la privacidad de sus datos, y excluir aquellas que no lo hagan.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "En los siguientes enlaces le facilitamos los accesos a las principales páginas de soporte:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "Asimismo, el Usuario puede oponerse al uso de determinadas cookies mediante los siguientes servicios:",
    cookiesPoliciesModalBrowserConfigCookiesLI1: "Bloqueo de cookies en Chrome",
    cookiesPoliciesModalBrowserConfigCookiesLI2: "Bloqueo de cookies en Safari",
    cookiesPoliciesModalBrowserConfigCookiesLI3:
      "Bloqueo de cookies en Firefox",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Bloqueo cookies en Internet Explorer",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "¿Qué ocurre si se deshabilitan las cookies?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "Si rechaza las cookies podrá seguir navegando por nuestro Sitio Web, aunque el uso de algunos de sus servicios podrá ser limitado y, por tanto, su experiencia puede resultar menos satisfactoria.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "El rechazo, bloqueo o no permisibilidad de las cookies descritas en la presente Política puede afectar a una correcta navegación por los contenidos reproducidos en el sitio web.",
    cookiesPoliciesModalAcceptCookies: "Aceptación de la política de cookies",
    cookiesPoliciesModalAcceptCookiesP1:
      "Pulsando el botón “ACEPTAR COOKIES” se asume que usted acepta el uso de cookies.",
    cookiesPoliciesModalAcceptCookiesP2:
      "No obstante, con cada inicio de sesión se mostrará la Política de cookies en la parte inferior de cualquier página del portal con el objeto de que usted sea consciente.",
    cookiesPoliciesModalUpgradeCookies:
      "¿Actualizamos nuestra política de cookies?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "Es posible que la información proporcionada en la presente Política de Cookies, pueda sufrir modificaciones debidas a actualizaciones o cambios en la política de tratamiento de cookies, así mismo, la Agencia Española de Protección de Datos pudiere dictar instrucciones y/o recomendaciones que hicieren variar la misma.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "Por ello le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies.",
    cookiesPoliciesModalRecipientCookies:
      "¿Quienes son los destinatarios de la información y coómo el uso de cookies afecta a la protección de datos personales?",
    cookiesPoliciesModalRecipientCookiesP1:
      "Para más información sobre el tratamiento de sus datos puede consultar la ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS:
      "Política de Protección de datos",
    cookiesPoliciesModalContactInfoCookies: "Información de contacto",
    cookiesPoliciesModalContactInfoCookiesP1:
      "Para cualquier duda, aclaración y/o sugerencia al respecto de las cookies, rogamos se pongan en contacto a través de correo electrónico ",
    cookiesPoliciesModalDatatableCookieType: "Tipo de Cookie",
    cookiesPoliciesModalDatatableTreatmentTerm: "Plazo de Tratamiento",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analíticas",
    cookiesPoliciesModalDatatableTypeSelf: "Propias",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Cada vez que un Usuario visita un Servicio, una herramienta de un proveedor externo genera una Cookie analítica en el ordenador del Usuario. Esta Cookie, que sólo se genera en la visita, servirá en próximas visitas a CCOMMS para identificar de forma anónima al visitante.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      "Permitir la identificación anónima de los Usuarios navegantes a través de la “Cookie” (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo. Identificar de forma anónima los contenidos más visitados y más atractivos para los Usuarios. Saber si el Usuario que está accediendo es nuevo o repite visita. Salvo que el Usuario decida registrarse en CCOMMS, la “Cookie” nunca irá asociada a ningún dato de carácter personal que pueda identificarle directamente. Dichas Cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la optimización y mejora de la experiencia de los Usuarios en el sitio.",
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Persistentes",
    cookiesPoliciesModalDatatableTypeSelfD:
      "Este tipo de Cookies permiten ampliar la información mostrada a cada Usuario anónimo en los Servicios de CCOMMS.",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Entre otros, se almacena la duración o frecuencia de visualización de las distintas secciones, la interacción con las mismas, o los patrones de navegación y/o comportamientos del Usuario.",
    cookiesPoliciesModalDatatableTypeSelfT: "Persistentes",
    description: "Descripción",
    privacyPoliciesConditionsFinalidadTitle: "Finalidad",
    accept: "Aceptar",
    reject: "Rechazar",
    save: "Guardar",
    cookiesPoliciesCertySign: "Cookies en Verificación de Certificados",
    cookiesNecessaryCookieDescription:
      "Esta cookie es necesaria y se utiliza para establecer la preferencia del usuario al respecto de la política de cookies.",
    cookiesGAMessage:
      "Medición interna con Google Analytics. Estas cookies almacenan un identificador único para reconocer al usuario las futuras ocasiones que visite el sitio web.",
    cookiesGAFGenericMessage: "Medición interna con Google Analytics."
  },
  conditionsOfService: 'Condiciones del servicio',
  certificationPractiseStatement: 'Declaración de prácticas del Servicio de Confianza',
  altAriaAttributes: {
    closeIcon: "icono cerrar",
    zoomTRID: "zoom trid",
    zoomCSV: "zoom csv",
    sampleCertificate: "Certificado modelo",
    downloadButton: "botón descargar",
    checkboxActiveUser: "Marcar casilla",
    selectLanguage: "Seleccionar idioma",
    openHelpDialog: "Abrir diálogo de soporte",
    trid: "Escribir TRID",
    csv: "Escribir CSV",
    part1Container: "1 - Indicador de posición del identificador único de transaccion (trid) en el certificado modelo",
    part2Container: "2 - Indicador de posición del identificador único de certificado (csv) en el certificado modelo",
    sampleTrid: "Identificador único de transacción (trid) en el certificado modelo",
    sampleCSV: "Identificador único de certificado (csv) en el certificado modelo"
  },
  activeVersion: "Versión en vigor",
  olderVersions: "Versiones anteriores",
  show: 'ver'
};
