export default {
  app: {
    header: {
      language: "Idioma"
    }
  },
  home: {
    title: "Verificação Online de Certificados",
    trid: "identificador único de transação (trid)",
    csv: "identificador único de certificado (csv)",
    description:
      "Complete os seguintes dados para verificar a autenticidade da declaração de finalização ou certificado que tem em seu poder.",
    subtitle: "CÓDIGO DE VALIDAÇÃO",
    validate: "Validar",
    resultError: "Reveja os dados introduzidos",
    messagesInputs: {
      required: "Requerido",
      minimo: "Mínimo 4 caracteres.",
      maximo: "Mínimo 1000 caracteres."
    }
  },
  confirmation: {
    title: "Os seus dados correspondem à seguinte certificação",
    subtitle:
      "Pode descarregar o certificado no botão inferior e verificar que coincide com as informações em seu poder.",
    download: "Descarregar",
    back: "Voltar",
    certificateDownloaded: "O seu certificado foi descarregado corretamente"
  },
  helpDialog: {
    messageSent: {
      title: "Contactar o suporte",
      subtitle:
        "Foi enviado o seu pedido ao nosso departamento de suporte, um agente entrará em contacto consigo assim que possível",
      back: "Voltar"
    },
    text: "Necessita de ajuda?",
    contactWithSupport: "Contactar o suporte",
    dialogPhrase:
      "Preencha os dados e a equipa de suporte técnico responder-lhe-á o mais brevemente possível. Horários de atendimento, comerciais:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>De Segunda a Sexta</strong>: 8h - 17h</p>",
    message: "Mensagem",
    sendRequest: "Enviar Pedido",
    requiredEmailFormat: "Formato de email incorreto",
    requiredEmail: "Email requerido",
    requiredMessage: "Mensagem requerida",
    requiredName: "Nome requerido",
    email: "Email",
    name: "Nome"
  },
  termsAndConditions: {
    title: "Termos e Condições",
    termsConditions1:
      "CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (doravante CCOMMS) na sua condição de titular e responsável pela presente APP, em cumprimento do disposto no artigo 10 da Lei 34/2002, de 11 de julho, de Serviços da Sociedade de Informação e de Comércio Eletrónico, põe à disposição dos utilizadores as seguintes informações desta APP:",
    companyInfo:
      "<p class='mb-1'><strong>Titular</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F</strong>: CB-86414000 </p><p class='mb-1'><strong>Domicílio Social</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Datos Registrales</strong>: Registo Comercial de Madrid no Tomo 29.801, Folio 98, Secção 8ª, Folha número M-536210.</p><p class='mb-1'><strong>Contacto: Telefone</strong>: 916 895 665</p><p class='mb-1'><strong>Email</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Correio eletrónico do Delegado de Proteção de Dados</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Delegado de Proteção de Dados da MailTecK'>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.- OBJETO",
    termsConditionsObjetoParrafo1:
      "As presentes Condições Gerais de Utilização (doravante, as «CGU») regulam a utilização da Aplicação Validação de certificado (doravante, a APP) que a CCOMMS põe à disposição dos utilizadores que acedam à mesma a fim de proceder a poder realizar o pedido realizado.",
    termsConditionsObjetoParrafo2:
      "Portanto, as presentes CGU aplicar-se-ão tanto à atividade promocional e de fornecimento de informações que se efetua através da APP como, por exemplo, a prestação dos serviços oferecidos no mesmo, de tal forma que as mesmas irão reger, sempre, tanto a simples navegação pela APP, como para a contratação de serviços no enquadramento da APP, se bem que estas últimas atividades adicionalmente, se submeterão tanto às CGU descritas em seguida, como às Condições Gerais de Contratação aplicáveis, e às Condições Particulares que, em cada caso, possam existir.",
    termsConditionsUsuariosTitulo: "II.- UTILIZADORES",
    termsConditionsUsuariosParrafo1:
      "O acesso e/ou a utilização da APP, atribui a quem o realiza a condição de utilizador aceitando, a partir desse mesmo momento, plenamente e sem reservas algumas, as presentes CGU, bem como as Condições Particulares que, em cada caso, complementem, modifiquem ou substituam as CGU relativamente a determinados serviços e conteúdos da APP, obrigando-se a respeitar as restrições de utilização de dita página estabelecidas pela CCOMMS em cada momento.",
    termsConditionsAccesoAPPTitulo: "III.- ACESSO À APP",
    termsConditionsAccesoAPPParrafo1:
      "O acesso e a utilização da APP tem caráter restringido e para poder aceder à mesma será necessário o registo prévio do utilizador.",
    termsConditionsUsoAPPTitulo: "IV.- UTILIZAÇÃO DA APP",
    termsConditionsUsoAPPParrafo1:
      "O utilizador compromete-se a utilizar a APP, os seus serviços e conteúdos sem ir contra a legislação vigente, a boa-fé, as utilizações geralmente aceites e a ordem pública. Assim, fica proibida a utilização da APP com fins ilícitos ou lesivos contra a CCOMMS ou qualquer terceiro, ou que, de qualquer forma, possam causar prejuízos ou impedir o normal funcionamento da APP.",
    termsConditionsResposabilidadTitulo:
      "V-. RESPONSABILIDADE DO UTILIZADOR POR DANOS E PREJUÍZOS",
    termsConditionsResposabilidadParrafo1:
      "A utilização da APP realizar-se-á sob a única e exclusiva responsabilidade do utilizador. Dita responsabilidade estender-se-á à utilização, por parte do utilizador, ou de qualquer terceiro, de quaisquer palavras-passe ou similares atribuídas para o acesso à APP, ou a quaisquer dos seus serviços.",
    termsConditionsResposabilidadParrafo2:
      "Sem prejuízo do anterior, a CCOMMS reserva-se o direito a negar ou desativar, a qualquer momento e sem necessidade de aviso prévio, o acesso à APP ou o acesso restrito aos utilizadores que não cumpram estas CGU, as normas estabelecidas pela CCOMMS ou seus colaboradores ou possam perturbar o bom funcionamento, a imagem, a credibilidade e/ou o prestígio da CCOMMS ou dos seus colaboradores.",
    termsConditionsResposabilidadParrafo3:
      "Neste sentido, a CCOMMS reserva-se a faculdade de cancelar ou restringir o acesso à APP a determinados utilizadores, no caso de observar quaisquer condutas que de acordo com a decisão da CCOMMS sejam contrárias a estas Condições de Utilização, à Lei, às normas estabelecidas pela CCOMMS ou seus colaboradores ou possam perturbar o bom funcionamento, a imagem, a credibilidade e/ou o prestígio da CCOMMS ou dos seus colaboradores. Assim, a CCOMMS poderá exigir responsabilidade aos Utilizadores pelos danos e prejuízos derivados da má utilização da APP.",
    termsConditionsResposabilidadParrafo4:
      "A CCOMMS não garante a licitude, a fiabilidade e a utilidade, bem como a sua veracidade ou exatidão dos conteúdos que os utilizadores transmitam durante a utilização da APP.",
    termsConditionsResposabilidadParrafo5:
      "A CCOMMS não controla a utilização que os utilizadores fazem da APP, nem garante que o façam de forma conforme as presentes Condições Gerais.",
    termsConditionsResposabilidadParrafo6:
      "A CCOMMS não controla, nem garante a ausência de vírus informáticos nos serviços prestados por terceiros através da APP que possam produzir alterações no seu sistema informático (software e hardware), ou nos documentos e ficheiros eletrónicos armazenados no seu sistema informático.",
    termsConditionsPropiedadIntelectualTitulo: "VI.- PROPRIEDADE INTELECTUAL",
    termsConditionsPropiedadIntelectualParrafo1:
      "Qualquer utilização ou modificação do Material ou dos Conteúdos para qualquer outro fim que não o autorizado nas Condições Gerais será considerado uma violação das leis internacionais de «copyright», que protegem os direitos de autor.",
    termsConditionsPropiedadIntelectualParrafo2:
      "Relativamente aos conteúdos é proibido:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "A sua reprodução, cópia, distribuição, modificação ou manipulação, de qualquer forma e com qualquer finalidade, a menos que se conte com a autorização prévia e por escrito dos seus legítimos titulares ou seja legalmente permitido.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "Qualquer violação dos direitos da CCOMMS ou dos seus legítimos titulares sobre os mesmos.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "A sua utilização para todo o tipo de fins comerciais ou publicitários, diferentes dos estritamente permitidos.",
    termsConditionsPropiedadIntelectualParrafo3:
      "A CCOMMS garante que os conteúdos, incluindo os de propriedade intelectual, não são de caráter ilícito, nem infringem a normativa vigente. Por conseguinte, os conteúdos da APP não terão caráter xenófobo, pornográfico, discriminatório, racista ou que de qualquer forma fomente a violência.",
    termsConditionsPropiedadIntelectualParrafo4:
      "Além disso, a CCOMMS adotará as medidas legais que considere convenientes para impedir qualquer tipo de conduta contrária à Lei ou à moral.",
    termsConditionsModificacionUnilateralTitulo: "VII.- MODIFICAÇÃO UNILATERAL",
    termsConditionsModificacionUnilateralParrafo1:
      "A CCOMMS poderá modificar, unilateralmente, e sem aviso prévio, sempre que o considere oportuno, a estrutura e o design da APP, bem como modificar ou eliminar os serviços, os conteúdos e as condições de acesso e/ou utilização da APP.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- EXCLUSÃO DE GARANTIAS DE RESPONSABILIDADE",
    termsConditionsExclusionGarantiasParrafo1:
      "A CCOMMS não outorga qualquer garantia, nem se responsabiliza, em caso algum, pelos danos e prejuízos de qualquer natureza que possam ter como causa:",
    termsConditionsExclusionGarantiasListaELemento1:
      "O incumprimento da Lei, da moral e dos bons costumes, bem como a ordem pública, ou a utilização dos produtos e/ou serviços oferecidos de forma não diligente ou incorreta, ou com fins ou efeitos ilícitos ou contrários ao estabelecido nas presentes CGU ou nas condições aplicáveis que em cada caso sejam aplicáveis.",
    termsConditionsExclusionGarantiasListaELemento2:
      "Pela aquisição ou contratação dos serviços, bens ou produtos que possam ser adquiridos ou contratados a terceiros através de um acesso na APP da CCOMMS, especialmente nos casos em que o processo de compra ou contratação se realiza diretamente na app de terceiros e ainda que apareçam distintivos ou uma «frame» com os elementos gráficos da app (Branding).",
    termsConditionsExclusionGarantiasListaELemento3:
      "A falta de disponibilidade, manutenção e eficiente funcionamento da APP e/ou dos seus serviços ou conteúdos.",
    termsConditionsExclusionGarantiasListaELemento4:
      "A falta de utilidade, adequação ou validade da APP e/ou dos seus serviços ou conteúdos para satisfazer necessidades, atividades ou resultados concretos ou expetativas dos utilizadores",
    termsConditionsExclusionGarantiasListaELemento5:
      "A existência de vírus, programas maliciosos ou lesivos nos conteúdos.",
    termsConditionsExclusionGarantiasListaELemento6:
      "A receção, obtenção, armazenamento, difusão ou transmissão, por parte dos utilizadores, dos conteúdos.",
    termsConditionsExclusionGarantiasListaELemento7:
      "A ilicitude ou falta de veracidade, exatidão, fiabilidade, pertinência, atualidade e exaustividade dos conteúdos e informações transmitidas ou postas à disposição dos utilizadores, incluindo as informações e serviços prestados por terceiros ou pelos utilizadores através da APP.",
    termsConditionsExclusionGarantiasListaELemento8:
      "O incumprimento por parte de terceiros das suas obrigações ou compromissos relativamente aos serviços prestados aos utilizadores através da APP.",
    termsConditionsExclusionGarantiasListaELemento9:
      "Dos conteúdos, ficheiros, informações, publicidade, opiniões, conceitos e imagens que não dependam da APP, nem sejam geridos pela CCOMMS. Nem sequer se responsabiliza pela má utilização que se realize dos conteúdos da APP, sendo exclusiva a responsabilidade de cada pessoa que aceda a eles, ou os utilize.",
    termsConditionsExclusionGarantiasListaELemento10:
      "Da utilização indevida das chaves de acesso dos utilizadores para o acesso aos conteúdos do serviço da APP que os requeiram e das consequências derivadas de qualquer natureza da má utilização por parte dos utilizadores, a sua perda ou esquecimento, e a sua utilização indevida por terceiros não autorizados.",
    termsConditionsExclusionGarantiasListaELemento11:
      "A incapacidade de qualquer utilizador ou o roubo de identidade de um terceiro efetuada pelo utilizador.",
    termsConditionsExclusionGarantiasListaELemento12:
      "Dos conteúdos fornecidos pelos utilizadores.",
    termsConditionsProteccionDatosTitulo: "IX.-  PROTEÇÃO DE DADOS",
    termsConditionsProteccionDatosParrafo1:
      "Pode consultar as informações detalhadas sobre Proteção de Dados na nossa página de",
    termsConditionsLeyJurisdiccionTitulo:
      "X.- LEGISLAÇÃO APLICÁVEL E JURISDIÇÃO",
    termsConditionsLeyJurisdiccionParrafo1:
      "Todas as Condições, Gerais e Particulares, inseridas na presente APP são regidas na normativa espanhola. Todos os conflitos, controvérsias ou situações derivadas das Condições Gerais e particulares submeter-se-ão aos Julgados e Tribunais da Vila de Madrid, renunciando expressamente as partes à sua jurisdição."
  },
  cookies: {
    cookiesPoliciesCompliance: "Política de Cookies",
    cookiesPoliciesIntroText:
      "Esta página web utiliza cookies para melhorar a sua experiência enquanto navega pela página web. Pode alterar estas definições a qualquer momento. As cookies classificadas como necessárias são armazenadas no seu navegador e são essenciais para o funcionamento das funcionalidades básicas da página web. As cookies de terceiros serão armazenadas no seu navegador web apenas com o seu consentimento e ajudam-nos a analisar e a compreender como se utiliza esta página web. Tem a opção de optar por não receber estas cookies, no entanto, a exclusão voluntária de alguma destas cookies pode fazer com que algumas funções deixem de estar disponíveis. Para obter informações sobre eliminar as cookies, consulte a função de ajuda do seu navegador.",
    cookiesPoliciesNecessaryCookies: "Cookies necessárias",
    cookiesPoliciesNecessaryCookiesText:
      "Este tipo de cookies é absolutamente essencial para que a página web funcione corretamente. Esta categoria só inclui cookies que garantam funcionalidades básicas e características de segurança da página web e não recolhem ou armazenam quaisquer informações pessoais sobre os utilizadores da página web.",
    cookiesPoliciesUnnecessaryCookies: "Cookies não necessárias",
    cookiesPoliciesUnnecessaryCookiesText:
      "As cookies que possam não ser particularmente necessárias para que a página web funcione se utilizam especificamente para recolher dados pessoais do utilizador através da análise, anúncios e outros conteúdos integrados denominam-se cookies não necessárias. É obrigatório obter o consentimento do utilizador antes de executar estas cookies na sua página web.",
    cookiesPoliciesGoogleAnalyticsCookies: "Cookies da Google Analytics",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "Para obter mais informações relativamente a este tipo de cookies, pode obter mais informações consultando o documento «Utilização das cookies da Google Analytics em páginas web",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Manter cookies ativas",
    cookiesNotificationBarText:
      "Utilizamos cookies, tanto próprias, como de terceiros, para medir as vistas, garantir a funcionalidade da página web e analisar a navegação no mesmo. Em seguida, pode aceitar as cookies ou configurar/recusar a utilização das mesmas. Pode obter mais informações sobre a titularidade da web, responsável pelo tratamento das cookies e dos seus dados de contacto na nossa",
    cookiesNotificationBarConfig: "Configurar",
    cookiesPoliciesModalIntro: "Introdução",
    cookiesPoliciesModalIntroP1:
      "Esta página web utiliza cookies e/ou tecnologias similares que armazenam e recuperam informações quando navega para melhorar e otimizar a experiência do utilizador.",
    cookiesPoliciesModalIntroP2:
      "Em geral, estas tecnologias podem servir para finalidades muito diversas como, por exemplo, reconhecer como utilizador, obter informações sobre hábitos de navegação, ou personalizar a forma como se mostram os conteúdos.",
    cookiesPoliciesModalIntroP3:
      "Em conformidade com o Real Decreto Lei 13/2012 e sua afetação no artigo 22.2 da Lei de Serviços da Sociedade de Informações e Comércio Eletrónico, mediante a presente Política informamos das diferentes cookies que a CUSTOMMER COMMUNICATIONS TECKNALIA, S.L. recolhe através dos diferentes meios ou canais de comunicação, próprios ou de terceiros, isentas ou não do dever de informar e os diferentes meios através dos quais pode recusar, permitir ou bloquear as mesmas.",
    cookiesPoliciesModalIntroP4:
      "Em seguida detalham-se informações sobre: o que são as «Cookies», que tipologia utiliza esta página web, como podem ser desativadas num navegador e como bloquear, especificamente, a instalação de Cookies de terceiros.",
    cookiesPoliciesModalResponsibility:
      "Quem é o responsável pela utilização das cookies na presente página web?",
    cookiesPoliciesModalResponsibilityP1:
      "O responsável pela utilização das cookies é a CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (doravante CCOMMS), com N.I.F. B-86414000 e com domicílio social em Leganés (Madrid) Avenida de la Recomba, 14, devidamente inscrita no Registo Comercial de Madrid no Tomo 29.801, Folio 98, Secção 8ª, Folha número M-536210.",
    cookiesPoliciesModalResponsibilityP2:
      "Endereço de correio eletrónico do Delegado de Proteção de Dados.",
    cookiesPoliciesModalWhatIsCookie: "O que é uma cookie?",
    cookiesPoliciesModalWhatIsCookieP1:
      "Uma cookie é um dado, ficheiro ou dispositivo de armazenamento e recuperação de dados (DARD), que um navegador web armazena de forma automática no dispositivo (Smartphone, tablet ou televisão ligada) de um utilizador ao aceder a páginas web, contas de redes sociais ou ao receber um email",
    cookiesPoliciesModalWhatIsCookieP2:
      "As cookies permitem armazenar e recuperar, de forma automática, dados sobre a utilização que se fez dos citados meios digitais e do equipamento, ou dispositivo, desde que acede aos mesmos.",
    cookiesPoliciesModalWhatIsCookieP3:
      "Uma cookie são apenas dados que se armazenam no computador do utilizador, pelo que não são software, nem sequer são fragmentos de código, são simplesmente dados. Portanto, em princípio as cookies não podem transmitir e executar vírus, nem instalar malware como cavalos de troia ou programas de espionagem.",
    cookiesPoliciesModalCookieTypes:
      "Que tipo de cookies existem na página web e sua finalidade",
    cookiesPoliciesModalCookiesInstall:
      "Que cookies podem ser instaladas na web de Verificação de Certificados?",
    cookiesPoliciesModalCookiesInstallP1:
      "O acesso da Web de Verificação de Certificados implica que se possam instalar as Cookies, próprias e/ou de terceiros, que se detalham no quadro seguinte:",
    cookiesPoliciesModalBrowserConfigCookies:
      "Como configurar as cookies no navegador",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      "Tem a opção de não receber cookies ou de ser informado sobre a sua fixação mediante a configuração do seu navegador para tal efeito, todos os cookies, salvo as de Flash, podem ser geridas a partir do seu navegador, normalmente na secção «Opções» ou «Preferências».",
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "Pode desativá-las de todo, em cujo caso o seu navegador não permitirá que se instale qualquer uma (impossibilitando que possa fazer uso de todas as funcionalidades da página web), ou apenas as que advenham de determinadas páginas web, o que lhe permitirá poder desfrutar das funcionalidades avançadas das webs que respeitam a privacidade dos seus dados, e excluir as que não o façam.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "Nas ligações seguintes indicamos-lhe os acessos às principais páginas de suporte:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "Assim, o Utilizador pode opor-se à utilização de determinadas cookies mediante os seguintes serviços:",
    cookiesPoliciesModalBrowserConfigCookiesLI1:
      "Bloqueio de cookies no Chrome",
    cookiesPoliciesModalBrowserConfigCookiesLI2:
      "Bloqueio de cookies no Safari",
    cookiesPoliciesModalBrowserConfigCookiesLI3:
      "Bloqueio de cookies no Firefox",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Bloqueio de cookies no Internet Explorer",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "O que acontece se se desativarem as cookies?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "Se recusar as cookies poderá continuar a navegar pela nossa página web, ainda que a utilização de alguns dos seus serviços possa ser limitada e, portanto, a sua experiência pode ser menos satisfatória.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "A recusa, bloqueio ou não autorização das cookies descritas na presente Política pode afetar uma navegação correta pelos conteúdos reproduzidos na página web",
    cookiesPoliciesModalAcceptCookies: "Aceitação da política de cookies",
    cookiesPoliciesModalAcceptCookiesP1:
      "Ao premir o botão «ACEITAR COOKIES» assume-se que aceita a utilização de cookies.",
    cookiesPoliciesModalAcceptCookiesP2:
      "Não obstante, com cada início de sessão indicar-se-á a Política de Cookies na parte inferior de qualquer página do portal com o objetivo de que fique consciente.",
    cookiesPoliciesModalUpgradeCookies:
      "Atualizamos a nossa política de cookies?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "É possível que as informações fornecidas na presente Política de Cookies possam sofrer modificações devido às atualizações ou alterações na política de tratamento de cookies, ou seja, a Agência Espanhola de Proteção de Dados pode indicar instruções e/ou recomendações que façam a mesma variar.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "Por isso recomendamos que consulte esta política sempre que aceder à nossa página web com o objetivo de estar adequadamente informado sobre como e para quê utilizamos as cookies.",
    cookiesPoliciesModalRecipientCookies:
      "Quem são os destinatários das informações e como a utilização de cookies afeta a proteção de dados pessoais?",
    cookiesPoliciesModalRecipientCookiesP1:
      "Para mais informações sobre o tratamento dos seus dados pode consultar a ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS:
      "Política de Proteção de Dados",
    cookiesPoliciesModalContactInfoCookies: "Informações de contacto",
    cookiesPoliciesModalContactInfoCookiesP1:
      "Para qualquer dúvida, clarificação e/ou sugestão relativamente às cookies, pedimos-lhe que entre em contacto através de correio eletrónico ",
    cookiesPoliciesModalDatatableCookieType: "Tipo de Cookie",
    cookiesPoliciesModalDatatableTreatmentTerm: "Prazo de Tratamento",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analíticas",
    cookiesPoliciesModalDatatableTypeSelf: "Próprias",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Sempre que um utilizador visita um Serviço, uma ferramenta de um fornecedor externo gera uma Cookie analítica no computador do Utilizador. Esta Cookie, que só se gera na visita, servirá nas próximas visitas à CCOMMS para identificar, de forma anónima o visitante.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      "Permitir a identificação anónima dos Utilizadores navegantes através da «Cookie» (identifica navegadores e dispositivos, não pessoas) e, portanto, a contabilização aproximada do número de visitantes e a sua tendência no tempo. Identificar, de forma anónima, os conteúdos mais visitados e mais atrativos para os Utilizadores. Saber se o Utilizador que está a aceder é novo ou visitante repetente. Salvo se o utilizador se decidir registar na CCOMMS, a «Cookie» nunca será associada a qualquer dado de caráter pessoal que o possa identificar diretamente. Ditas Cookies só serão utilizadas com propósitos estatísticos que ajudem à otimização e melhoria da experiência dos Utilizadores na página web.",
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Persistentes",
    cookiesPoliciesModalDatatableTypeSelfD:
      "Este tipo de Cookies permite ampliar as informações mostradas a cada Utilizador anónimo nos Serviços da CCOMMS",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Entre outros, armazena-se a duração ou frequência de visualização das diferentes secções, a interação com as mesmas, ou os padrões de navegação e/ou comportamentos do Utilizador.",
    cookiesPoliciesModalDatatableTypeSelfT: "Persistentes",
    description: "Descrição",
    privacyPoliciesConditionsFinalidadTitle: "Finalidade",
    accept: "Aceitar",
    reject: "Recusar",
    save: "Guardar",
    cookiesPoliciesCertySign: "Cookies em Verificação de Certificados",
    cookiesNecessaryCookieDescription:
      "Esta cookie é necessária e utiliza-se para estabelecer a preferência do utilizador relativamente à política de cookies.",
    cookiesGAMessage:
      "Medição interna com a Google Analytics. Estas cookies armazenam um identificador único para reconhecer o utilizador nas futuras ocasiões que visite a página web.",
    cookiesGAFGenericMessage: "Medição interna com a Google Analytics."
  },
  conditionsOfService: 'Condições de serviço',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "ícone de fechamento",
    zoomTRID: "zoom trid",
    zoomCSV: "zoom csv",
    sampleCertificate: "Certificado de Amostra",
    downloadButton: "botão de download",
    checkboxActiveUser: "Caixa de seleção",
    selectLanguage: "Selecionar idioma",
    openHelpDialog: "Abrir caixa de diálogo de suporte",
    trid: "Escreva TRID",
    csv: "Gravar CSV",
    part1Container: "1 - Indicador de posição do identificador único da transação (trid) no certificado modelo",
    part2Container: "2 - Indicador de posição do identificador único de certificado (csv) no modelo de certificado",
    sampleTrid: "Identificador de transação exclusivo (trid) no certificado de amostra",
    sampleCSV: "Identificador exclusivo do certificado (csv) no certificado de amostra"
  }
};
