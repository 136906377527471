export default {
  "app": {
    "header": {
      "language": "Lingua"
    }
  },
  "home": {
    "title": "Verifica online dei certificati",
    "trid": "Identificativo univoco delle transazioni (trid)",
    "csv": "Identificativo univoco del certificato (csv)",
    "description": "Compili i seguenti dati per verificare l'autenticità della dichiarazione di completamento o del certificato di cui dispone.",
    "subtitle": "CODICE DI VERIFICA",
    "validate": "Verifica",
    "resultError": "Controlli i dati inseriti",
    "messagesInputs": {
      "required": "Campo obbligatorio",
      "minimo": "Minimo 4 caratteri.",
      "maximo": "Minimo 1000 caratteri."
    }
  },
  "confirmation": {
    "title": "I suoi dati corrispondono alla seguente certificazione",
    "subtitle": "Può scaricare il certificato premendo sul pulsante inferiore e verificare che coincida con le informazioni di cui dispone.",
    "download": "Scarica",
    "back": "Indietro",
    "certificateDownloaded": "Il suo certificato è stato scaricato correttamente"
  },
  "helpDialog": {
    "messageSent": {
      "title": "Contatta l'assistenza",
      "subtitle": "È stata inviata una richiesta al nostro dipartimento di assistenza; un agente la contatterà il prima possibile.",
      "back": "Indietro"
    },
    "text": "Ha bisogno d'aiuto?",
    "contactWithSupport": "Contatta l'assistenza",
    "dialogPhrase": "Compili i dati e i nostri agenti del dipartimento di assistenza tecnica le risponderanno il prima possibile. Orari di assistenza nei giorni lavorativi:",
    "monFriHours": "<p class='body-2 font-weight-medium mb-7'><strong>Dal lunedì al venerdì</strong>: dalle ore 8:00 alle ore 17:00</p>",
    "message": "Messaggio",
    "sendRequest": "Invia richiesta",
    "requiredEmailFormat": "Il formato dell'e-mail è errato",
    "requiredEmail": "È necessario inserire l'e-mail",
    "requiredMessage": "È necessario inserire un messaggio",
    "requiredName": "È necessario inserire il nome",
    "email": "E-mail",
    "name": "Nome"
  },
  "termsAndConditions": {
    "title": "Termini e condizioni",
    "termsConditions1": "CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (di seguito CCOMMS) in qualità di titolare e responsabile della presente APP, in conformità a quanto previsto dall'articolo 10 della Legge 34/2002 dell'11 luglio sui Servizi della società dell'informazione e del commercio elettronico, mette a disposizione degli utenti le seguenti informazioni generali di questa APP:",
    "companyInfo": "<p class='mb-1'><strong>Titolare</strong> Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>P.IVA</strong>: CB-86414000 </p><p class='mb-1'><strong>Indirizzo fiscale</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Dati registro</strong>: Iscritta al registro delle imprese di Madrid, Volume 29.801, Foglio 98, Sezione 8ª, Pagina n. M-536210.</p><p class='mb-1'><strong>Contatto: Telefono</strong>: 916 895 665</p><p class='mb-1'><strong>E-mail</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>E-mail del Delegato della Protezione dei dati</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Delegato della Protezione dei dati di MailTeck'>dpo@mailteck.com</a></p>",
    "termsConditionsObjetoTitulo": "I.-OGGETTO",
    "termsConditionsObjetoParrafo1": "Le presenti Condizioni Generali d'uso (di seguito “CGU”) regolano l'uso dell'Applicazione Verifica del certificato (di seguito, l'APP) messa a disposizione degli utenti da CCOMMS al fine di poter eseguire la richiesta inoltrata.",
    "termsConditionsObjetoParrafo2": "Pertanto, le presenti CGU si applicheranno all'attività promozionale, alle informazioni e ai servizi prestati attraverso l'App; regolano, quindi, sia la semplice navigazione all'interno dell'APP che l'eventuale contrattazione di servizi in essa proposti, fermo restando che quest'ultima dovrà inoltre essere conforme sia alle CGU descritte di seguito che alle Condizioni Generali di Contrattazione applicabili e alle Condizioni Specifiche, se del caso.",
    "termsConditionsUsuariosTitulo": "II.-UTENTI",
    "termsConditionsUsuariosParrafo1": "L'accesso e/o l'uso dell'APP attribuisce a chi lo effettua la condizione di utente che, da quel momento, accetta pienamente e senza riserve le presenti CGU, le Condizioni Specifiche che, se del caso, completano, modificano o sostituiscono le CGU in relazione a determinati servizi e contenuti dell'APP, e si impegna a rispettare le restrizioni d'uso dell'APP stabilite da CCOMMS.",
    "termsConditionsAccesoAPPTitulo": "III.-ACCESSO ALL'APP",
    "termsConditionsAccesoAPPParrafo1": "L'accesso e l'uso dell'APP sono riservati. Per accedervi sarà necessaria la registrazione dell'utente.",
    "termsConditionsUsoAPPTitulo": "IV.-USO DELL'APP",
    "termsConditionsUsoAPPParrafo1": "L'utente si impegna ad utilizzare l'APP, i suoi servizi e contenuti in buona fede, nel rispetto della legislazione vigente, dell'ordine pubblico e degli usi generalmente accettati.  Analogamente, è severamente proibito l'uso dell'APP con finalità illecite o lesive nei confronti di CCOMMS o di terzi ovvero che possano in qualsiasi modo danneggiare o impedire il normale funzionamento dell'APP.",
    "termsConditionsResposabilidadTitulo": "V-. RESPONSABILITÀ DELL'UTENTE PER EVENTUALI DANNI O PERDITE",
    "termsConditionsResposabilidadParrafo1": "L'utente è l'unico responsabile dell'uso dell'APP. Tale responsabilità si estenderà all'uso di qualsiasi password o codice assegnato per accedere all'APP o a uno qualsiasi dei servizi in essa inclusi da parte dell'utente o di terze parti.",
    "termsConditionsResposabilidadParrafo2": "Fermo restando quanto sopra, CCOMMS si riserva il diritto di negare, inabilitare o limitare in qualsiasi momento e senza previo avviso l'accesso all'APP agli utenti che non rispettino le presenti CGU, le norme stabilite da CCOMMS o dai suoi collaboratori o che possano alterare il corretto funzionamento, l'immagine, la credibilità e/o il prestigio di CCOMMS o dei suoi collaboratori.",
    "termsConditionsResposabilidadParrafo3": "In tal senso, CCOMMS si riserva la facoltà di eliminare o limitare l'accesso all'APP ad alcuni Utenti, qualora si osservassero comportamenti che CCOMMS consideri contrari alle presenti Condizioni d'uso, alla Legge, alle norme stabilite da CCOMMS o dai suoi collaboratori ovvero che possano alterare il corretto funzionamento, l'immagine, la credibilità e/o il prestigio di CCOMMS o dei suoi collaboratori. Inoltre, CCOMMS potrà esigere che gli Utenti siano ritenuti responsabili per eventuali danni causati da un cattivo uso dell'APP.",
    "termsConditionsResposabilidadParrafo4": "CCOMMS non garantisce la legittimità, affidabilità e utilità, nonché veracità o esattezza, dei contenuti trasmessi dagli utenti quando utilizzano l'APP.",
    "termsConditionsResposabilidadParrafo5": "CCOMMS non controlla l'utilizzo dell'APP da parte degli utenti, né garantisce che ne facciano un uso conforme alle presenti Condizioni Generali.",
    "termsConditionsResposabilidadParrafo6": "CCOMMS non controlla né garantisce l'assenza di virus informatici nei servizi prestati da terzi attraverso l'APP che possano produrre alterazioni nel sistema informatico (software e hardware) nonché nei documenti e nei file elettronici salvati sul suo sistema informatico.",
    "termsConditionsPropiedadIntelectualTitulo": "VI.- PROPRIETÀ INTELLETTUALE",
    "termsConditionsPropiedadIntelectualParrafo1": "Qualsiasi uso o modifica del Materiale o dei Contenuti per finalità diverse da quelle autorizzate nelle Condizioni Generali sarà considerato una violazione delle leggi internazionali di “copyright” che proteggono i diritti d'autore.",
    "termsConditionsPropiedadIntelectualParrafo2": "Relativamente ai contenuti, ne è proibita:",
    "termsConditionsPropiedadIntelectualListaELemento1": "la riproduzione, copia, distribuzione, modifica o manipolazione, in qualsiasi modo e per qualsiasi finalità, salvo che sia legalmente concessa o che si disponga di autorizzazione preventiva e per iscritto da parte dei legittimi proprietari.",
    "termsConditionsPropiedadIntelectualListaELemento2": "Qualsiasi violazione dei diritti di CCOMMS o dei legittimi titolari.",
    "termsConditionsPropiedadIntelectualListaELemento3": "L'utilizzo dei contenuti per finalità commerciali o pubblicitarie diverse da quelle strettamente permesse.",
    "termsConditionsPropiedadIntelectualParrafo3": "CCOMMS garantisce che i contenuti, inclusi quelli di proprietà intellettuale, non sono di carattere illecito né violano la normativa vigente. Pertanto, i contenuti dell'APP non saranno di carattere xenofobo, pornografico, discriminatorio, razzista né fomenteranno in alcun modo la violenza.",
    "termsConditionsPropiedadIntelectualParrafo4": "Inoltre, CCOMMS adotterà le misure legali che ritiene opportune per prevenire qualsiasi tipo di comportamento illegale o contrario alla Legge.",
    "termsConditionsModificacionUnilateralTitulo": "VII.- MODIFICA UNILATERALE",
    "termsConditionsModificacionUnilateralParrafo1": "Se lo ritiene opportuno, CCOMMS potrà modificare unilateralmente e senza preavviso la struttura e il progetto dell'APP, nonché modificare o eliminare i servizi, i contenuti e le condizioni di accesso e/o uso dell'APP.",
    "termsConditionsExclusionGarantiasTitulo": "VIII.-ESCLUSIONE DELLE GARANZIE E RESPONSABILITÀ",
    "termsConditionsExclusionGarantiasParrafo1": "CCOMMS non concede garanzie né si assume la responsabilità in nessun caso per danni di qualsivoglia natura che possono derivare dalle seguenti circostanze:",
    "termsConditionsExclusionGarantiasListaELemento1": "violazione della Legge, degli usi, dell'ordine pubblico e del buon costume; uso negligente o incorretto dei prodotti e/o servizi offerti o con finalità illecite o contrarie a quanto previsto dalle presenti CGU o dalle condizioni applicabili, se del caso.",
    "termsConditionsExclusionGarantiasListaELemento2": "Acquisto o richiesta di servizi, beni e prodotti da terzi tramite l'accesso all'APP di CCOMMS, soprattutto quando il processo di acquisto o richiesta del servizio viene effettuato direttamente nell'app dei suddetti terzi, anche se vengono utilizzati marchi o “frame” con elementi grafici specifici dell'app (Branding).",
    "termsConditionsExclusionGarantiasListaELemento3": "Mancata disponibilità, manutenzione ed effettivo funzionamento dell'APP e/o dei suoi servizi e contenuti.",
    "termsConditionsExclusionGarantiasListaELemento4": "Mancata utilità, adeguamento o validità dell'APP e/o dei suoi servizi o contenuti per soddisfare le esigenze, le attività o i risultati specifici e le aspettative degli utenti.",
    "termsConditionsExclusionGarantiasListaELemento5": "Esistenza di virus, malware o programmi lesivi.",
    "termsConditionsExclusionGarantiasListaELemento6": "Ricezione, ottenimento, conservazione, diffusione o trasmissione dei contenuti da parte degli utenti.",
    "termsConditionsExclusionGarantiasListaELemento7": "Illegalità o la mancanza di veracità, accuratezza, affidabilità, pertinenza, tempestività e completezza del contenuto e delle informazioni trasmesse o rese disponibili agli utenti, comprese le informazioni e i servizi forniti da terzi o da utenti attraverso l'APP.",
    "termsConditionsExclusionGarantiasListaELemento8": "Mancato rispetto da parte di terzi dei propri obblighi e impegni in relazione ai servizi forniti agli utenti attraverso l'APP.",
    "termsConditionsExclusionGarantiasListaELemento9": "Contenuti, file, informazioni, pubblicità, opinioni, concetti e immagini che non dipendono dall'APP, né sono gestiti da CCOMMS. Altresì si solleva da ogni responsabilità per il cattivo uso dei contenuti all'interno dell'APP, essendo quest'ultimo una responsabilità esclusiva delle persone che accedono o utilizzano tali contenuti.",
    "termsConditionsExclusionGarantiasListaELemento10": "Né è responsabile dell'uso scorretto delle password degli utenti per accedere ai contenuti del servizio dell'APP richiesto né delle conseguenze di qualsiasi natura derivanti da un cattivo uso da parte degli utenti, dalla perdita o smarrimento di suddette password nonché dell'uso indebito da parte di terzi non autorizzati.",
    "termsConditionsExclusionGarantiasListaELemento11": "Incapacità di qualsiasi utente o il soppiantamento della personalità di una terza parte creato dall'utente.",
    "termsConditionsExclusionGarantiasListaELemento12": "Contenuti forniti dagli utenti.",
    "termsConditionsProteccionDatosTitulo": "IX.- PROTEZIONE DATI",
    "termsConditionsProteccionDatosParrafo1": "È possibile consultare le informazioni dettagliate sulla Protezione dei Dati sul nostro sito web ",
    "termsConditionsLeyJurisdiccionTitulo": "X.- NORMATIVA APPLICABILE E GIURISDIZIONE",
    "termsConditionsLeyJurisdiccionParrafo1": "Tutte le Condizioni, Generali e Specifiche, contenute nella presente APP sono disciplinate dalla normativa spagnola. Eventuali conflitti, controversie o situazioni derivanti dalle Condizioni Generali e specifiche saranno di competenza dei Tribunali della città di Madrid, e le parti rinunciano a qualsiasi giurisdizione che possa competere loro."
  },
  "cookies": {
    "cookiesPoliciesCompliance": "Politica dei cookie",
    "cookiesPoliciesIntroText": "Questo sito web usa cookie per migliorare la sua esperienza durante la navigazione. È possibile cambiare queste impostazioni in qualsiasi momento. I cookie classificati come “necessari” verranno salvati sul suo browser e sono essenziali per il funzionamento basilare del sito web. I cookie di terzi verranno salvati sul suo browser solo dietro il suo consenso e ci aiutano ad analizzare e comprendere come viene usato il sito web. Può scegliere di non ricevere questi cookie. Ad ogni modo, l'esclusione volontaria di uno di questi cookie può comportare la mancata disponibilità di alcune funzionalità. Per ottenere maggiori informazioni su come eliminare i cookie, consulti la funzione di Aiuto del suo browser.",
    "cookiesPoliciesNecessaryCookies": "Cookie necessari",
    "cookiesPoliciesNecessaryCookiesText": "Si tratta di una tipologia di cookie essenziale per far funzionare correttamente il sito web. Questa categoria include solo i cookie che garantiscono le funzionalità basilari e le caratteristiche di sicurezza del sito web e non raccolgono né salvano alcuna informazione personale sugli utenti del sito web.",
    "cookiesPoliciesUnnecessaryCookies": "Cookie non necessari",
    "cookiesPoliciesUnnecessaryCookiesText": "Si definiscono cookie non necessari quelli che non sono particolarmente essenziali per il corretto funzionamento del sito web. Si usano in modo specifico per raccogliere dati personali dell'utente attraverso l'analisi, gli annunci e altri contenuti integrati. Prima di eseguire questi cookie su un sito web è necessario ottenere il consenso dell'utente.",
    "cookiesPoliciesGoogleAnalyticsCookies": "Cookie di Google Analytics",
    "cookiesPoliciesGoogleAnalyticsCookiesText": "Per maggiori informazioni su questa tipologia di cookie, consulti il documento “Uso dei cookie di Google Analytics sui siti web”",
    "cookiesPoliciesGoogleAnalyticsCheckboxText": "Mantenere cookie attivi",
    "cookiesNotificationBarText": "Utilizziamo cookie, sia nostri che di terze parti, per misurare le visite al nostro sito web, garantirne la funzionalità e analizzare la navigazione al suo interno. Di seguito potrà accettare o rifiutare i cookie o configurarne l'uso. Può accedere alle informazioni sulla titolarità del sito web, sul responsabile del trattamento dei cookie e sui dati di contatto su",
    "cookiesNotificationBarConfig": "Imposta",
    "cookiesPoliciesModalIntro": "Introduzione",
    "cookiesPoliciesModalIntroP1": "Il nostro sito web usa cookie e/o tecnologie simili a quelle che conservano e recuperano informazioni durante la navigazione per migliorare e ottimizzare l'esperienza dell'utente.",
    "cookiesPoliciesModalIntroP2": "In generale, queste tecnologie compiono finalità molto diverse, tra cui il riconoscimento dell'utente, l'ottenimento di informazioni sulle abitudini di navigazione, oppure personalizzano la visualizzazione del contenuto.",
    "cookiesPoliciesModalIntroP3": "In conformità al Decreto Regio Legge 13/2012 e alle modifiche nell'articolo 22.2 della Legge sui Servizi della Società delle informazioni e del commercio elettronico, con la presente Politica le indichiamo quali sono i diversi cookie che CUSTOMER COMMUNICATIONS TECKNALIA, S.L. raccoglie tramite i diversi canali di comunicazione, propri e di terze parti, esenti o meno dell'obbligo di informare, e i diversi mezzi attraverso i quali può rifiutarli, permetterli o bloccarli.",
    "cookiesPoliciesModalIntroP4": "Di seguito verranno fornite informazioni dettagliate su: cosa sono i “cookie”, quali tipologie di cookie sono usate sul nostro sito web, come possono essere disattivati in un browser e come bloccare in modo specifico l'installazione di cookie di terze parti.",
    "cookiesPoliciesModalResponsibility": "Chi è il responsabile dell'uso dei cookie in questo sito web?",
    "cookiesPoliciesModalResponsibilityP1": "Il responsabile dell'uso dei cookie è CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (di seguito CCOMMS), P.IVA B-86414000, sede fiscale a Leganés (Madrid) in Avenida de la Recomba, 14, azienda debitamente iscritta al Registro delle Imprese di Madrid, Volume 29.801, Foglio 98, Sezione 8ª, Pagina n. M-536210.",
    "cookiesPoliciesModalResponsibilityP2": "E-mail del Delegato della Protezione dei dati.",
    "cookiesPoliciesModalWhatIsCookie": "Cos'è un cookie?",
    "cookiesPoliciesModalWhatIsCookieP1": "Un cookie è un dato, un file o dispositivo di archiviazione e recupero dati (DARD) che un browser salva in modo automatico nel dispositivo (Smartphone, tablet o smart-tv) di un utente quando questi accede ai siti web, agli account dei canali social o quando riceve un'e-mail.",
    "cookiesPoliciesModalWhatIsCookieP2": "I cookie permettono di archiviare e recuperare in modo automatico i dati sull'uso effettuato nei suddetti canali digitali e sul computer o dispositivo da cui è stato effettuato l'accesso.",
    "cookiesPoliciesModalWhatIsCookieP3": "Un cookie si compone unicamente di dati archiviati nel computer di un utente, pertanto non si tratta di software né di frammenti di codice, ma semplicemente di dati. Pertanto, in linea di principio i cookie non possono trasmettere né eseguire virus, né installare malware come trojan o programmi di spionaggio.",
    "cookiesPoliciesModalCookieTypes": "Tipologie di cookie del sito web e finalità",
    "cookiesPoliciesModalCookiesInstall": "Quali sono i cookie che si possono installare sul sito web Verifica dei Certificati?",
    "cookiesPoliciesModalCookiesInstallP1": "L'accesso al sito web Verifica dei Certificati implica che si possono installare Cookie, propri e di terzi, dettagliati nel riquadro di seguito:",
    "cookiesPoliciesModalBrowserConfigCookies": "Come configurare i cookie nel browser",
    "cookiesPoliciesModalBrowserConfigCookiesP1": "Lei ha l'opzione di non ricevere cookie o di essere informato dei cookie configurati dalle impostazioni del suo browser, da cui è possibile gestirli nelle sezioni “Opzioni” o “Preferenze”, fatta eccezione per i cookie Flash.",
    "cookiesPoliciesModalBrowserConfigCookiesP2": "Può disattivarli tutti e in tal caso il browser non permetterà l'installazione di nessun cookie (bloccando alcune funzionalità del sito web) oppure solo quelli provenienti da specifici siti web. In tal caso potrà utilizzare tutte le funzionalità avanzate dei siti web che rispettano la privacy dei suoi dati ed escludere quelli che non lo fanno.",
    "cookiesPoliciesModalBrowserConfigCookiesP3": "Nei link di seguito le indichiamo gli accessi ai principali siti web di assistenza:",
    "cookiesPoliciesModalBrowserConfigCookiesP4": "Inoltre, l'Utente può opporsi all'uso di determinati cookie tramite i servizi indicati di seguito:",
    "cookiesPoliciesModalBrowserConfigCookiesLI1": "Blocco dei cookie su Chrome",
    "cookiesPoliciesModalBrowserConfigCookiesLI2": "Blocco dei cookie su Safari",
    "cookiesPoliciesModalBrowserConfigCookiesLI3": "Blocco dei cookie su Firefox",
    "cookiesPoliciesModalBrowserConfigCookiesLI4": "Blocco dei cookie su Internet Explorer",
    "cookiesPoliciesModalBrowserConfigCookiesLI5": "http://www.criteo.com/deactivate-criteo-banners/",
    "cookiesPoliciesModalBrowserConfigCookiesLI6": "http://youronlinechoices.eu/",
    "cookiesPoliciesModalBrowserConfigCookiesLI7": "http://www.networkadvertising.org/choices/",
    "cookiesPoliciesModalUnavailableCookies": "Cosa succede se i cookie vengono disabilitati?",
    "cookiesPoliciesModalUnavailableCookiesP1": "Rifiutando i cookie potrà continuare a navigare sul nostro sito web, anche se potrebbe essere limitato l'uso di alcuni servizi e pertanto la sua esperienza potrebbe essere meno soddisfacente.",
    "cookiesPoliciesModalUnavailableCookiesP2": "Il rifiuto, il blocco o la negazione dei permessi dei cookie descritti nella presente politica può influire sulla corretta navigazione dei contenuti riprodotti nel sito web.",
    "cookiesPoliciesModalAcceptCookies": "Accettazione della politica dei cookie",
    "cookiesPoliciesModalAcceptCookiesP1": "Facendo clic sul pulsante “ACCETTA COOKIE” accetta l'uso dei cookie.",
    "cookiesPoliciesModalAcceptCookiesP2": "Tuttavia, a ogni accesso le verrà mostrata e ricordata la nostra Politica dei cookie, nella parte inferiore di qualsiasi pagina del portale.",
    "cookiesPoliciesModalUpgradeCookies": "Aggiorniamo la nostra politica dei cookie?",
    "cookiesPoliciesModalUpgradeCookiesP1": "È possibile che le informazioni contenute nella presente Politica dei cookie possano subire modifiche a causa di aggiornamenti o cambiamenti nella politica di trattamento dei cookie; inoltre, l'Agenzia Spagnola per la Protezione dei dati potrebbe dettare istruzioni e/ raccomandazioni che potrebbero modificare la suddetta politica.",
    "cookiesPoliciesModalUpgradeCookiesP2": "Pertanto, le consigliamo di controllare la presente politica ogni volta che accede al nostro Sito Web al fine di essere adeguatamente informato sulle modalità e sulle finalità dell'uso dei cookie.",
    "cookiesPoliciesModalRecipientCookies": "Chi sono i destinatari delle informazioni e in che modo l'uso dei cookie influisce sulla protezione dei dati personali?",
    "cookiesPoliciesModalRecipientCookiesP1": "Per ulteriori informazioni sul trattamento dei suoi dati personali può consultare la ",
    "cookiesPoliciesModalRecipientCookiesLinkCCOMMS": "Politica sulla protezione dei dati",
    "cookiesPoliciesModalContactInfoCookies": "Informazioni di contatto",
    "cookiesPoliciesModalContactInfoCookiesP1": "Per qualsiasi dubbio, chiarimento e/o suggerimento relativo ai cookie, la preghiamo di contattarci tramite e-mail",
    "cookiesPoliciesModalDatatableCookieType": "Tipo di cookie",
    "cookiesPoliciesModalDatatableTreatmentTerm": "Durata del trattamento",
    "cookiesPoliciesModalDatatableTypeAnalytics": "Analitici",
    "cookiesPoliciesModalDatatableTypeSelf": "Propri",
    "cookiesPoliciesModalDatatableTypeAnalyticsD": "Ogni volta che un Utente visita un Servizio, uno strumento di un fornitore esterno genera un cookie analitico nel computer dell'Utente. Tale cookie, che si genera solamente nel momento della visita, servirà a CCOMMS nelle visite successive per identificare anonimamente il visitatore.",
    "cookiesPoliciesModalDatatableTypeAnalyticsF": "Questi cookie permettono, inoltre, di identificare in modo anonimo gli Utenti naviganti (identificano browser e dispositivi, non le persone) e pertanto di contabilizzare approssimativamente il numero di visitatori e la tendenza nel tempo. Identificano in modo anonimo i contenuti più visitati e interessanti per gli Utenti. Consentono di sapere se l'Utente che sta effettuando l'accesso è nuovo o meno. Salvo che l'Utente decida di registrarsi su CCOMMS, il “cookie” non verrà associato a nessun dato di carattere personale che possa identificarlo direttamente. I suddetti cookie verranno utilizzati solo a fini statistici che contribuiscano a ottimizzare e migliorare l'esperienza degli Utenti nel sito web.",
    "cookiesPoliciesModalDatatableTypeAnalyticsT": "Persistenti",
    "cookiesPoliciesModalDatatableTypeSelfD": "Questo tipo di cookie permette di ampliare le informazioni mostrate a ogni Utente anonimo nei Servizi di CCOMMS.",
    "cookiesPoliciesModalDatatableTypeSelfF": "Tra le altre funzioni, questi cookie archiviano la durata e la frequenza della visualizzazione delle diverse sezioni, l'interazione con le stesse, gli schemi di navigazione e/o i comportamenti degli utenti.",
    "cookiesPoliciesModalDatatableTypeSelfT": "Persistenti",
    "description": "Descrizione",
    "privacyPoliciesConditionsFinalidadTitle": "Finalità",
    "accept": "Accetta",
    "reject": "Rifiuta",
    "save": "Salva",
    "cookiesPoliciesCertySign": "Cookie in Verifica dei Certificati",
    "cookiesNecessaryCookieDescription": "Questo cookie è necessario per stabilire la preferenza dell'utente rispetto alla politica dei cookie.",
    "cookiesGAMessage": "Misurazione interna con Google Analytics. Questi cookie archiviano un identificativo univoco per riconoscere l'utente nelle sue future visite al sito web.",
    "cookiesGAFGenericMessage": "Misurazione interna con Google Analytics."
  },
  conditionsOfService: 'Condizioni di servizio',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "icona chiudi",
    zoomTRID: "trid zoom",
    zoomCSV: "zoom csv",
    sampleCertificate: "Certificato di esempio",
    downloadButton: "pulsante download",
    checkboxActiveUser: "Casella di controllo",
    selectLingua: "Seleziona lingua",
    openHelpDialog: "Apri finestra di dialogo di supporto",
    trid: "Scrivi TRID",
    csv: "Scrivi CSV",
    part1Container: "1 - Indicatore di posizione dell'identificativo univoco della transazione (trid) nel modello di certificato",
    part2Container: "2 - Indicatore di posizione dell'identificativo univoco del certificato (csv) nel modello di certificato",
    sampleTrid: "Identificativo univoco della transazione (trid) nel certificato di esempio",
    sampleCSV: "Identificatore univoco del certificato (csv) nel certificato di esempio"
  }
};
