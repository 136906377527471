export default {
  app: {
    header: {
      language: "Language"
    }
  },
  home: {
    title: "Online Certificate Validation",
    trid: "UNIQUE TRANSACTION IDENTIFIER (TRID)",
    csv: "UNIQUE CERTIFICATE IDENTIFIER (CSV)",
    description:
      "Please complete the following information to verify the authenticity of the completion statement or certificate in your possession.",
    subtitle: "VALIDATION CODE",
    validate: "Validate",
    resultError: "Please review the data entered",
    messagesInputs: {
      required: "Required",
      minimo: "Minimum 4 characters",
      maximo: "Minimum 1000 characters"
    }
  },
  confirmation: {
    title: "Your data corresponds to the following certification",
    subtitle:
      "You can download the certificate by clicking on the button below and check that it matches the information in your possession.",
    download: "Download",
    back: "Back",
    certificateDownloaded: "Your certificate has been successfully downloaded"
  },
  helpDialog: {
    messageSent: {
      title: "Contact the support team",
      subtitle:
        "Your request was sent to the support team, an agent will contact you as soon as possible.",
      back: "Back"
    },
    text: "Need help?",
    contactWithSupport: "Contact the support team",
    dialogPhrase:
      "Fill in the data and the technical support team will answer you as soon as possible. Hours of operation, working days:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>Monday to Friday</strong>: 8:00 h - 17:00 h</p>",
    message: "Message",
    sendRequest: "Send Request",
    requiredEmailFormat: "Incorrect email format",
    requiredEmail: "Email required",
    requiredName: "Name required",
    requiredMessage: "Message required",
    email: "E-mail",
    name: "Name"
  },
  termsAndConditions: {
    title: "Terms and Conditions",
    termsConditions1:
      " CUSTOMER COMMUNICATIONS TECKNALIA, SL, (hereinafter CCOMMS) in its capacity as owner and controller of this APP, in compliance with the provisions of article 10 of Law 34/2002, of 11 July, on Services of the Society of Information and Electronic Commerce, offers the following general information about this APP to users:",
    companyInfo:
      "<p class='mb-1'><strong>Responsible</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F</strong>: CB-86414000 </p><p class='mb-1'><strong>Registered office</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Registry data</strong>: Registro Mercantil de Madrid al Tomo 29.801, Folio 98, Sección 8ª, Hoja número M-536210.</p><p class='mb-1'><strong>Contact: Phone nr.</strong>: 916 895 665</p><p class='mb-1'><strong>Email</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Email address of the Data Protection Officer</strong>: <a href='mailto:dpo@mailteck.com' class='text-decoration-none' title='Data Protection Officer of MailTecK'>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.- PURPOSE",
    termsConditionsObjetoParrafo1:
      "These General Conditions of Use (hereinafter, the “GCU”) regulate the use of the application CertySign (hereinafter, the APP) that CCOMMS makes available to users who access it in or7der to proceed with the request made",
    termsConditionsObjetoParrafo2:
      "Therefore, these GCU will apply both to the promotional activity and provision of information carried out through the APP and to the provision of services offered therein, in such a manner that they will be applicable at all times both for simple browsing through the APP, and also for contracting services within the APP, although the latter will be subject, additionally, to both the GCU described below, and the applicable General Contracting Conditions, as well as the Particular Conditions that may exist, if any.",
    termsConditionsUsuariosTitulo: "II.- USERS",
    termsConditionsUsuariosParrafo1:
      "Access to and / or use of the APP, attributes to the person who performs it the status of user, accepting, from that moment, fully and without reservation, these UGC, as well as the Particular Conditions that, if applicable, complement, modify or replace the UGC in relation to certain services and contents of the APP, obliging to respect the restrictions of use of said page established by CCOMMS at all times.",
    termsConditionsAccesoAPPTitulo: "III.- ACCESS TO THE APP",
    termsConditionsAccesoAPPParrafo1:
      "Access and use of the APP is restricted so prior registration by the user will be necessary to access it.",
    termsConditionsUsoAPPTitulo: "IV.- USE OF THE APP",
    termsConditionsUsoAPPParrafo1:
      "The user agrees to use the APP, its services and content without violating current legislation, good faith, generally accepted uses or public order. Furthermore, the use of the APP for illegal purposes of that may be harmful to CCOMMS or any third party or that in any way may cause damages or prevent the normal functioning of the APP is prohibited.",
    termsConditionsResposabilidadTitulo: "V-. USER LIABILITY FOR DAMAGES",
    termsConditionsResposabilidadParrafo1:
      "The user is solely and exclusively liable for the use they make of the APP. Said liability will extend to the use by the user or any third party of any passwords or similar assigned to access the APP or any of its services.",
    termsConditionsResposabilidadParrafo2:
      "The foregoing notwithstanding, CCOMMS reserves the right to deny or disable at any time and without the need for prior notice, access to the APP or to the restricted access, for users who do not comply with these GCU, the rules established by CCOMMS or their workers or who interfere with the proper operation, image, credibility and/or prestige of CCOMMS or its workers.",
    termsConditionsResposabilidadParrafo3:
      "In this regard, CCOMMS reserves the right to cancel or restrict access to the APP to certain Users, if it detects any behaviour that in the opinion of CCOMMS is contrary to these Conditions of Use, the Law, the rules established by CCOMMS or its workers or that may interfere with the proper operation, image, credibility and/or prestige of CCOMMS or its workers.. Furthermore, CCOMMS may hold Users liable for damages arising from the improper use of the APP.",
    termsConditionsResposabilidadParrafo4:
      "CCOMMS does not guarantee the legality, reliability and usefulness, nor the veracity or accuracy of the content that users transmit while using the APP.",
    termsConditionsResposabilidadParrafo5:
      "CCOMMS does not control the use that users make of the APP nor does it guarantee that they do so in accordance with these General Conditions.",
    termsConditionsResposabilidadParrafo6:
      "CCOMMS does not control or guarantee the absence of computer viruses in the services provided by third parties through the APP that may cause alterations in your computer system (software and hardware) or in the electronic documents and files stored in your computer system.",
    termsConditionsPropiedadIntelectualTitulo: "VI.- INTELLECTUAL PROPERTY",
    termsConditionsPropiedadIntelectualParrafo1:
      "Any use or modification of the Material or the Content for any purpose other than that authorised in the General Conditions will be considered a breach of international «copyright» laws, which protect copyright.",
    termsConditionsPropiedadIntelectualParrafo2:
      "Regarding the content, it is prohibited:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "Its reproduction, copy, distribution, modification, or manipulation in any way and for any purpose, except when there is the prior written authorization of its legitimate owners or it is legally permitted.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "Any violation of the rights of CCOMMS or the legitimate owners thereof.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "Its use for all types of commercial or advertising purposes other than those strictly permitted.",
    termsConditionsPropiedadIntelectualParrafo3:
      "CCOMMS guarantees that the content, including that which is intellectual property, is not illegal nor infringes the current regulations. Therefore, the APP will not include content that is discriminatory, pornographic, xenophobic or that in any way encourages violence.",
    termsConditionsPropiedadIntelectualParrafo4:
      "In addition, CCOMMS will adopt the legal measures it deems appropriate to prevent any type of conduct that is immoral or contravenes the Law.",
    termsConditionsModificacionUnilateralTitulo:
      "VII.- UNILATERAL MODIFICATION",
    termsConditionsModificacionUnilateralParrafo1:
      "CCOMMS may unilaterally modify, without prior notice, whenever it deems appropriate, the APP’s structure and design, as well as modify or delete services, content and conditions of access and/or use of the APP.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- EXCLUSION OF GUARANTEES AND LIABILITY",
    termsConditionsExclusionGarantiasParrafo1:
      "CCOMMS does not grant any guarantee nor is it responsible, in any case, for damages of any kind that could be caused by:",
    termsConditionsExclusionGarantiasListaELemento1:
      "Failure to comply with the Law, morality, generally accepted standards or public order or the negligent or improper use of the products and/or services offered, for illegal purposes or effects or contrary to what is established in these GCU or in the applicable conditions that may be applicable.",
    termsConditionsExclusionGarantiasListaELemento2:
      "For the purchase or contracting of services, goods or products that may be acquired or contracted from third parties through access via the COMMS APP, especially in those cases in which the purchase or contracting process is carried out directly in the third-party app and even if distinguishing marks or a “frame” appear with the app’s graphic elements (Branding).",
    termsConditionsExclusionGarantiasListaELemento3:
      "The lack of availability, maintenance and effective operation of the APP and/or its services or content.",
    termsConditionsExclusionGarantiasListaELemento4:
      "The lack of usefulness, suitability or validity of the APP and/or its services or content in satisfying the needs, activities or specific results or expectations of users.",
    termsConditionsExclusionGarantiasListaELemento5:
      "The existence of viruses or malware in the content.",
    termsConditionsExclusionGarantiasListaELemento6:
      "The reception, obtaining, storage, dissemination or transmission by the users of the content.",
    termsConditionsExclusionGarantiasListaELemento7:
      "The illegality or lack of veracity, accuracy, reliability, relevance, up-to-dateness and completeness of the content and information transmitted or made available to users, including information and services provided by third parties or by users through the APP.",
    termsConditionsExclusionGarantiasListaELemento8:
      "The breach by third parties of their obligations or commitments in relation to the services provided to users through the APP.",
    termsConditionsExclusionGarantiasListaELemento9:
      "For the content, files, information, advertising, opinions, concepts and images that do not depend on the APP, nor are managed by CCOMMS. Nor is COMMS liable for improper use of the APP content, this being the sole responsibility of the person who accesses or uses said content.",
    termsConditionsExclusionGarantiasListaELemento10:
      "For improper use of user access codes for access to the APP service content requiring such codes nor for the consequences of any nature derived from improper use by users, for lost or forgotten codes, and improper use thereof by unauthorized third parties.",
    termsConditionsExclusionGarantiasListaELemento11:
      "The incapacity of any user or the impersonation of a third party by the user.",
    termsConditionsExclusionGarantiasListaELemento12:
      "For the content facilitated by users.",
    termsConditionsProteccionDatosTitulo: "IX.- PRIVACY POLICY",
    termsConditionsProteccionDatosParrafo1:
      "You can view the detailed information on Data Protection on our privacy policy page.",
    termsConditionsLeyJurisdiccionTitulo: "X.- APPLICABLE LAW AND JURISDICTION",
    termsConditionsLeyJurisdiccionParrafo1:
      "All the General and Particular Conditions included in this APP are governed by Spanish law. Any dispute, controversy or situation derived from the General and Particular Conditions will be submitted to the Courts of the City of Madrid, with the parties expressly waiving their jurisdiction."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as GRUPO MAILCOMMS within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Security and Privacy Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In GRUPO MAILCOMMS, the information you have provided has been collected by one of the companies of GRUPO MAILCOMMS, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that GRUPO MAILCOMMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "Grupo MailComms, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionContent: "<div class=\"datos-content\">Version: 8 | Date: 26 November 2024<br /><br /><p><strong\n" +
      "      >ANNEX I: INFORMATION SECURITY, CONTINUITY AND PRIVACY MANAGEMENT SYSTEM POLICY</strong\n" +
      "    ></p>\n" +
      "  <p>This Information Security, Continuity and Privacy Policy shows the Management&apos;s commitment by providing the organisation with resources, duties and procedures to guarantee compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organisation, as well as that of our clients.</p>\n" +
      "  <p>Grupo MailComms, aware that the security, continuity and privacy of information relating to our clients is a valuable resource, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of the ISO/IEC 27001, ISO/IEC 27701 and ISO 22301 Standards to guarantee the continuity of information systems, minimise the risk of damage and ensure compliance with set objectives.</p>\n" +
      "  <p>Additionally, for the Electronic Registered Delivery Service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 Standards for Electronic Registered Delivery Services Providers, which contain specific precepts for the provision of this type of service. In this regard, the Policy governing this service shall be the Practice Statement of Electronic Registered Delivery Trust Service (hereinafter &quot;Practice Statement&quot;).</p>\n" +
      "  <p>Furthermore, in compliance with European cybersecurity regulations, the entities forming part of the Group, in their capacity as Qualified Trust Service Providers, undertake to comply with Directive (EU) 2022/2555 on measures to ensure a common high level of cybersecurity throughout the Union, as essential entities of the European digital infrastructure.</p>\n" +
      "  <p>The objective of the Security, Continuity and Privacy Policy is to establish the framework for action required to protect information resources against threats, whether internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.</p>\n" +
      "  <p>The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct duty of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.</p>\n" +
      "  <p>Moreover, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company&apos;s activity.</p>\n" +
      "  <p>The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.</p>\n" +
      "  <p>Any person whose activity may directly or indirectly be affected by the requirements of the Information Security, Continuity and Privacy Management System is obliged to strictly comply with the Security, Continuity and Privacy Policy and, additionally, for the Electronic Registered Delivery Service, must comply with the Practice Statement.</p>\n" +
      "  <p>The Grupo MailComms shall implement all required measures to comply with the applicable regulations on security, continuity and privacy in general and on IT security, relating to IT policy, the security of buildings and facilities and the behaviour of employees and third parties associated with the Grupo MailComms in the use of IT systems and the processing of personal data. The measures required to ensure the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of information, essential for:</p>\n" +
      "  <ul>\n" +
      "    <li>Complying with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the goal of achieving ongoing improvement of the organisation and of the security, continuity and privacy management system.</li>\n" +
      "    <li>Ensuring the confidentiality of the data managed by Grupo MailComms.</li>\n" +
      "    <li>Ensuring the availability of information systems, both in the services offered to clients and in internal management.</li>\n" +
      "    <li>Ensuring emergency response capability, restoring critical services to operation in the shortest possible time.</li>\n" +
      "    <li>Avoiding undue alterations to information.</li>\n" +
      "    <li>Promoting awareness and training in information security, continuity, privacy and trustworthy e-services.</li>\n" +
      "    <li>Promoting and participating in achieving ongoing improvement of the information security, continuity and privacy management system and trusted e-services.</li>\n" +
      "    <li>Establishing technical and methodological requirements for cybersecurity risk management measures, identifying risks and correcting detected vulnerabilities, cyber threats and cybersecurity incidents, in order to prevent the occurrence of incidents that compromise the business continuity of Grupo MailComms.</li>\n" +
      "    <li>Updating the results of the risk assessment and risk processing plan at planned intervals and at least annually, and when there are significant changes in operations or significant risks or incidents.</li>\n" +
      "  </ul>\n" +
      "  <p>The Information Security and Privacy Policy and the Practice Statement should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organisation, to ensure that their suitability, adequacy and effectiveness are maintained.</p>\n" +
      "</div>",
    privacyPoliciesConditionsPoliticaRedesTitle: "Network and Information Systems Security Policy",
    privacyPoliciesConditionsPoliticaRedesSubtitle: "Grupo MailComms, You may consult Network and Information Systems Security Policy",
    privacyPoliciesConditionsPoliticaRedesContent: "<div class=\"datos-content\">Version: 4 | Date: 4 December 2024<br /><br /><p><strong\n" +
      "      >ANNEX II: NETWORK AND INFORMATION SYSTEMS SECURITY POLICY</strong\n" +
      "    ></p>\n" +
      "  <p>The management of <strong>Grupo MailComms</strong> (formed by MAILTECK S.A. and CUSTOMER COMMUNICATIONS TECKNALIA S.L.), hereinafter &quot;Grupo MailComms&quot; or, specifically, &quot;Mailteck&quot; and/or &quot;CCOMMS&quot;, aware of the need to promote, maintain and improve the client focus in all its activities, has implemented an Integrated Management System (IMS) in compliance with several internationally recognised standards. The ultimate goal of the IMS is to ensure that we understand and share the needs and goals of our clients, striving to provide services that meet their expectations and working towards ongoing improvement. Grupo MailComms expressly states its commitment to enhance <strong>Information Security and Cybersecurity</strong>, specifically, to ensure, among other aspects, the security of networks and information systems that support the different business processes in such a way as to reinforce its digital operational resilience, as well as the <strong>continuity and availability</strong> of the service provided, aligning its practices with the applicable regulations in force.</p>\n" +
      "  <p>In this regard, Grupo MailComms is committed to developing the highest cybersecurity capabilities, thereby reducing threats to the network and information systems used by Grupo MailComms in compliance with European cybersecurity regulations.</p>\n" +
      "  <p>At the same time, it is committed to meet the needs and expectations of stakeholders, to maintain high competitiveness in services and products for the <strong\n" +
      "      >&ldquo;Automated processing of multi-channel client communications and transactions, Issuance of electronic and paper documents, as well as Electronic Certification of security documents and Trust Services: Qualified Electronic Registered Delivery Service, Electronic Signature and Contracting; Electronic and Postal Notifications and Communications; Electronic Identification and Authentication and Digital Archiving&rdquo;.</strong\n" +
      "    ></p>\n" +
      "  <p><strong>GRUPO MAILCOMMS&apos;S ROLE REGARDING DIRECTIVE (EU) 2022/2555</strong></p>\n" +
      "  <p>MAILTECK and CCOMMS, in their capacity as Qualified Trust Service Providers, are considered essential entities of the European digital infrastructure in accordance with Directive (EU) 2022/2555 on measures to ensure a high common level of cybersecurity throughout the Union, commonly referred to as the &quot;NIS 2 Directive&quot;, which entails their status as obliged entities.</p>\n" +
      "  <p>For this reason, this Policy is part of the security strategy pursued by Grupo MailComms, with the objective of ensuring that the use of networks and information systems complies with the rights of interested parties and safeguards the highest standards of cybersecurity, taking into account the Group&apos;s activities and structure.</p>\n" +
      "  <p>Grupo MailComms will therefore become responsible for compliance with the obligations required by these regulations.</p>\n" +
      "  <p><strong>MISSION and OBJECTIVES:</strong></p>\n" +
      "  <ul>\n" +
      "    <li>Encourage ongoing improvement of client services and support.</li>\n" +
      "    <li>Continue to position <strong>Grupo MailComms</strong> as a benchmark in the industry.</li>\n" +
      "    <li>Implement, maintain and test our business continuity arrangements to ensure that vital information and services are available to our clients when required.</li>\n" +
      "    <li>Provide clients with the most professional equipment and to have highly qualified technicians, experts in the required disciplines and accustomed to working as a team, available immediately and for as long as required.</li>\n" +
      "    <li>Provide adequate resources that are required to implement this Policy, including the necessary staff, financial resources, processes, tools and technologies.</li>\n" +
      "    <li>Provide service delivery based on our commitment to ongoing improvement of our systems, with security, cyber security and information continuity as a central pillar.</li>\n" +
      "    <li>Encourage and promote a culture of cybersecurity among all its professionals and subjects bound by this Policy, either internally or among its clients and suppliers.</li>\n" +
      "    <li>Implement an information security culture through training and awareness raising.</li>\n" +
      "  </ul>\n" +
      "  <p><strong>Our mission and objectives are achieved through:</strong></p>\n" +
      "  <ul>\n" +
      "    <li>A system of objectives, metrics and indicators for ongoing improvement, monitoring, measurement of our internal processes and client satisfaction. Establishing and monitoring compliance with contractual requirements to ensure an efficient and safe service.</li>\n" +
      "    <li>Integrating indicators linked to the level of implementation and maturity of security measures into the ISMS metrics system.</li>\n" +
      "    <li>Continuously training and raising the awareness of our team in order to offer the highest degree of professionalism and specialisation possible.</li>\n" +
      "    <li>Maintaining our infrastructures in an adequately and in accordance with the requirements of our clients.</li>\n" +
      "    <li>Organisation and implementation of security processes.</li>\n" +
      "    <li>Staff management.</li>\n" +
      "    <li>Authorisation and access control.</li>\n" +
      "    <li>Security by default.</li>\n" +
      "    <li>System integrity and upgrade.</li>\n" +
      "    <li>Protection of information in storage and in transit.</li>\n" +
      "    <li>Prevention against other interconnected information systems.</li>\n" +
      "    <li>Implementing a secure product procurement management procedure.</li>\n" +
      "    <li>Complying with the requirements of current legislation, especially regarding the Protection of Personal Data: GDPR and LOPDGDD.</li>\n" +
      "    <li>Complying with the Policies and Procedures defined in our Security Documentation.</li>\n" +
      "    <li>Introducing ongoing improvement processes that enable us to make permanent progress in our Information Security management.</li>\n" +
      "    <li>Managing and developing plans for the management and processing of risks with a standards-based risk analysis and management methodology used.</li>\n" +
      "    <li>Securely managing internal and external communications, as well as information stored and in transit.</li>\n" +
      "    <li>Ensuring interconnection with other information systems.</li>\n" +
      "    <li>Managing and monitoring activity with log management.</li>\n" +
      "    <li>Daily management of measures aimed at the protection and security of networks and information systems, designing robust security measures that are aligned with the needs of the variety of stakeholders, as well as with the current regulations applicable in this area, for which Grupo MailComms approves the specific policies and/or procedures that develop the basic principles and requirements for the security of networks and information systems established in this Policy. The objective is to identify risks and correct detected vulnerabilities, cyber threats and cybersecurity incidents in order to prevent the materialisation of incidents that compromise the business continuity of Grupo MailComms.</li>\n" +
      "    <li>Defining policies and procedures for security incident management.</li>\n" +
      "    <li>Ensuring business and service continuity and availability.</li>\n" +
      "    <li>Ensuring that our Assets and Services comply with ENS High Level measures for the dimensions of Confidentiality, Integrity, Availability, Authenticity and Traceability.</li>\n" +
      "    <li>Establishing, implementing and applying security procedures and/or policies in the supply chain governing relations with direct suppliers and service providers and establishing the appropriate measures and controls in order to mitigate the risks detected arising from these third parties for the security of the Group&apos;s networks and information systems.</li>\n" +
      "  </ul>\n" +
      "  <p>Moreover, these principles should be addressed in the following <strong>security areas:</strong></p>\n" +
      "  <ul>\n" +
      "    <li><strong>Physical:</strong> This includes the security of premises, facilities, hardware systems, media and any physical asset that processes or may process information, as well as physical access.</li>\n" +
      "    <li><strong>Logic:</strong> Including protection aspects of applications, networks, electronic communication, computer systems and logical access.</li>\n" +
      "    <li><strong>Corporate/policy:</strong> It consists of security aspects related to the organisation itself, internal rules, standards and legal regulations.</li>\n" +
      "  </ul>\n" +
      "  <p>The ultimate goal of information security is to ensure that an organisation can meet its objectives using information systems. The following basic principles should be taken into account in security decisions:</p>\n" +
      "  <ol style=\"list-style-type: lower-alpha\">\n" +
      "    <li>Organisation and implementation of security process.</li>\n" +
      "    <li>Risk analysis and management.</li>\n" +
      "    <li>Staff management.</li>\n" +
      "    <li>Professionalism.</li>\n" +
      "    <li>Authorisation and access control.</li>\n" +
      "    <li>Protection of facilities.</li>\n" +
      "    <li>Procurement of security products and contracting of security services.</li>\n" +
      "    <li>Minimal privilege.</li>\n" +
      "    <li>System integrity and upgrade.</li>\n" +
      "    <li>Protection of information in storage and in transit.</li>\n" +
      "    <li>Prevention against other interconnected information systems.</li>\n" +
      "    <li>Activity logging and detection of malicious code.</li>\n" +
      "    <li>Security incidents.</li>\n" +
      "    <li>Business continuity.</li>\n" +
      "    <li>Ongoing improvement of the security process.</li>\n" +
      "  </ol>\n" +
      "  <p>All cybersecurity documentation developed in execution of the requirements set out in this point is managed, structured and maintained in accordance with the documented procedures that Grupo MailComms has developed taking into account national and international technical standards and regulations that apply in each case. A minimum retention period of 5 years has been established for the cybersecurity regulatory framework and evidence of compliance. Under the Qualified Electronic Delivery Service, the Practice Statements and the final Statement of Service will be retained for 15 years.</p>\n" +
      "  <p><strong>Security roles or duties in the ENS framework:</strong></p>\n" +
      "  <p><u>Information Controller</u>: They shall determine the (security) requirements of the information processed according to the parameters of Annex I of the ENS.</p>\n" +
      "  <ul>\n" +
      "    <li>They have the ultimate responsibility for the use of the information and, therefore, for its protection. They are ultimately responsible for any error or negligence leading to an incident of confidentiality or integrity (in terms of data protection) and availability (in terms of information security).</li>\n" +
      "  </ul>\n" +
      "  <p><u>System Manager</u>: They determine the requirements for the services provided.</p>\n" +
      "  <ul>\n" +
      "    <li>They have the following duties:<ul>\n" +
      "        <li>Develop, operate and maintain the information system throughout its life cycle, including its specification, installation and verification of its correct operation.</li>\n" +
      "        <li>Define the topology and management of the information system, establishing the criteria for its use and the services available in it.</li>\n" +
      "        <li>Ensure that security measures are properly integrated into the overall security framework.</li>\n" +
      "      </ul>\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p><u>Information Security Officer</u>: They determine decisions to meet information security and service requirements.</p>\n" +
      "  <ul>\n" +
      "    <li>The two key duties of the Security Officer are:<ul>\n" +
      "        <li>Maintain the security of the information handled and the services provided by the information systems in the area they are responsible for, in accordance with the organisation&apos;s Information Security Policy.</li>\n" +
      "        <li>Promote information security training and awareness within the area they are responsible for.</li>\n" +
      "      </ul>\n" +
      "    </li>\n" +
      "  </ul>\n" +
      "  <p><u>Service Manager</u>: They shall determine the (security) requirements of the services provided according to the parameters of Annex I of the ENS.</p>\n" +
      "  <ul>\n" +
      "    <li>They have the power to establish the security requirements of the service. The determination of the levels in each security dimension is undertaken within the framework established in Annex I of the National Security Scheme and the assessment criteria are supported by the Security Policy.</li>\n" +
      "  </ul>\n" +
      "  <p><strong>Grupo MailComms</strong>&apos;s<u>Information Security and Privacy Committee</u> (ISPC), with the objective of regulating and implementing information security and privacy measures and policies, as well as regulations aimed at adapting information systems to current personal data protection legislation, has the following duties, among others:</p>\n" +
      "  <ul>\n" +
      "    <li>Appoint the IPSMS Manager and approve their duties.</li>\n" +
      "    <li>Approve the start of the implementation of the IPSMS.</li>\n" +
      "    <li>Review and approve the Security and Privacy Policy.</li>\n" +
      "    <li>Approve the Information Security and Privacy System Documentation, as well as new editions or modifications.</li>\n" +
      "    <li>Monitor the implementation and operation of the Information Security and Privacy System.</li>\n" +
      "    <li>Analysis of client complaints.</li>\n" +
      "    <li>Periodically assess the degree of exposure to risks affecting the Grupo Mailcomms &apos;s information systems and personal data processing.</li>\n" +
      "  </ul>\n" +
      "  <p><u>The following make up the IPSC</u>:</p>\n" +
      "  <ul>\n" +
      "    <li>CEO.</li>\n" +
      "    <li>Head of Technology.</li>\n" +
      "    <li>Head of IT Operations.</li>\n" +
      "    <li>Head of Printing Operations.</li>\n" +
      "    <li>Head of Finance, HR and Legal</li>\n" +
      "    <li>Head of MailComms Sales</li>\n" +
      "    <li>Head of Technical</li>\n" +
      "    <li>Head of Marketing</li>\n" +
      "    <li>Head of International Market</li>\n" +
      "    <li>Head of IPSMS</li>\n" +
      "  </ul>\n" +
      "  <p>Considering these guidelines, management confirms its firm commitment to join efforts to achieve these objectives, and therefore this policy is understood, implemented and kept up to date at all levels of the organisation.</p>\n" +
      "  <p><strong>Structuring system safety documentation</strong></p>\n" +
      "  <p>The system documentation follows the following structure:</p><img\n" +
      "     style=\"margin:0 auto;\"\n" +
      "      src=\"/anexo2_img01_eng.jpg\"\n" +
      "      alt=\"Documentation structure\"\n" +
      "    /><p>The classification of the information in the system is classified into the following categories, as set out in the PE-SGSPI 01-ERDS_Documentation Development and Control procedures:</p>\n" +
      "  <ul>\n" +
      "    <li>Public Use</li>\n" +
      "    <li>Internal Use</li>\n" +
      "    <li>Confidential</li>\n" +
      "  </ul>\n" +
      "  <p><strong\n" +
      "      >Applicable legislation on the processing of personal data</strong\n" +
      "    ></p>\n" +
      "  <p>With regard to the processing of personal data, the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals regarding the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC and the corresponding national legislation shall be taken into account.</p>\n" +
      "  <p>The applicable legal and regulatory framework can be found in the RE01-PE_SGSPI18 Applicable Laws and Regulations document.</p>\n" +
      "  <p><strong>Monitoring, approval, publication and review</strong></p>\n" +
      "  <p>The IPSC shall approve the entity&apos;s Security, Cybersecurity, Continuity and Privacy Policies, publish and/or distribute them to all its employees, as well as to third parties that may be involved in the implementation of information systems.</p>\n" +
      "  <p>The IPSC manager, and the manager of the corresponding Service, regarding the specific policies of each service, shall be responsible for making them public or, where appropriate, communicating them and always maintaining them in the Grupo MailComms systems.</p>\n" +
      "  <p>The Information Security, Cybersecurity, Continuity and Privacy Policies and service-specific policies should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organisation, to ensure that their suitability, adequacy and effectiveness are maintained.</p>\n" +
      "  <p>The list of Policies applicable in the framework of the ENS and IPSC-ERDS can be found in the &ldquo;RE01-PE IPSC-ERDS 01 List of documents and records of the IPSC-ERDS&rdquo; document .</p>\n" +
      "  <p>On the other hand, the list of Policies applicable in the framework of ERDS:</p>\n" +
      "  <ul>\n" +
      "    <li>Mailteck Practice Statement of Electronic Registered Delivery Trust Service</li>\n" +
      "    <li>Customer Comms Practice Statement of Electronic Registered Delivery Trust Service.</li>\n" +
      "    <li>Annex I Policy IPSC-ERDS.</li>\n" +
      "  </ul>\n" +
      "  <p><sup>1 </sup>Directive (EU) 2022/2555 of the European Parliament and of the Council of 14 December 2022 on measures for a common high level of cybersecurity across the Union, amending Regulation (EU) No 910/2014 and Directive (EU) 2018/1972 and repealing Directive 2016/1148.</p>\n" +
      "  <p style=\"margin:0 auto;\" class=\"trext-center\">LEGAL FRAMEWORK: Royal Decree 311/2022, of 3 May, amending Royal Decree 3/2010, of 8 January, which regulates the Spanish Security Scheme in the field of Electronic Administration.</div>\n" +
      "</div>"



  },
  cookies: {
    cookiesPoliciesCompliance: "Cookies policy",
    cookiesPoliciesIntroText:
      "This website uses cookies to improve your experience while browsing the website. You can change these settings at any time. Cookies classified as necessary are stored in your browser and are essential for the basic functionality of the website. Third-party cookies will be stored in your browser only with your consent and they will help us analyse and understand how this website is used. You have the option of not receiving these cookies, though the voluntary exclusion of any of these may mean that some functions are no longer available. For information on deleting cookies please consult your browser’s help function.",
    cookiesPoliciesNecessaryCookies: "Essential cookies",
    cookiesPoliciesNecessaryCookiesText:
      "These types of cookies are absolutely essential for the website to function properly. This category only cookies that guarantee the basic functionality and security features of the website and do not collect or store any personal information about website users.",
    cookiesPoliciesUnnecessaryCookies: "Non-essential cookies",
    cookiesPoliciesUnnecessaryCookiesText:
      "Cookies that may not be particularly necessary for the website to function and are specifically used to collect personal data from the user through analysis of advertisements and other embedded content are called non-essential cookies. Consent must be obtained from the user before executing these cookies on your website.",
    cookiesPoliciesGoogleAnalyticsCookies: "Google Analytics cookies",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "For more information on this type of cookie, you can consult the document on 'Use of Google Analytics cookies on websites'",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Activate cookies",
    cookiesNotificationBarText:
      "We use both our own and third-party cookies to measure visits, guarantee the functionality of the website and analyse browsing habits. Below you can accept or configure/reject the cookies. You can find more information on the ownership of the website that controls the processing of cookies and your contact information in our cookies policy:",
    cookiesNotificationBarConfig: "Configure",
    cookiesPoliciesModalIntro: "Introduction",
    cookiesPoliciesModalIntroP1:
      "This website uses cookies and/or similar technologies that store and retrieve information during your browsing session in order to improve and optimise your user experience.",
    cookiesPoliciesModalIntroP2:
      "In general, these technologies can serve many different purposes, such as recognising you as a user, obtaining information about your browsing habits, or customising the way content is displayed.",
    cookiesPoliciesModalIntroP3:
      "In accordance with Royal Decree Act 13/2012 and the terms of Article 22.2 of the Information Society and E-Commerce Services Act, this Policy hereby informs you of the cookies collected by CUSTOMER COMMUNICATIONS TECKNALIA, S.L. through different media or communication channels, whether proprietary or third-party, exempt or otherwise from the duty to inform, and of the different means by which such cookies can be rejected, allowed or blocked.",
    cookiesPoliciesModalIntroP4:
      "Below you can find information about: what cookies are, what type of cookies this website uses, how to disable them in your browser and how to specifically block the installation of third-party cookies.",
    cookiesPoliciesModalResponsibility:
      "Who is responsible for cookies on this website?",
    cookiesPoliciesModalResponsibilityP1:
      "The entity responsible for cookies is CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (hereinafter CCOMMS), holder of tax number (N.I.F.) B-86414000, with registered office in Leganés (Madrid) Avenida de la Recomba, 14, duly registered in the Companies Register of Madrid in Volume 29,801, Folio 98, Section 8, Sheet number M-536210.",
    cookiesPoliciesModalResponsibilityP2:
      "Email address of the Data Protection Officer.",
    cookiesPoliciesModalWhatIsCookie: "What is a cookie",
    cookiesPoliciesModalWhatIsCookieP1:
      "A cookie is a piece of data, a file or a data storage and retrieval device that is downloaded to your system (smartphone, tablet or Smart TV) when you access our website or social media accounts or when you receive an email.",
    cookiesPoliciesModalWhatIsCookieP2:
      "Cookies allow us to automatically store and retrieve data on how you use our digital media and about the system or device you use to access them.",
    cookiesPoliciesModalWhatIsCookieP3:
      "A cookie is merely data stored on the user's computer, i.e. it is not a form of software or code fragments. In consequence, cookies cannot transmit or execute viruses, nor install malware such as Trojans or spyware.",
    cookiesPoliciesModalCookieTypes:
      "What type of cookies are on the website, and what is their purpose",
    cookiesPoliciesModalCookiesInstall:
      "What cookies can be installed on the Certificate Verification website?",
    cookiesPoliciesModalCookiesInstallP1:
      "The access of The Certificate Verification Website implies that the Cookies, own and / or third party, can be installed, which are detailed in the following table:",
    cookiesPoliciesModalBrowserConfigCookies:
      "How to configure cookies in your browser",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      'You can choose to receive cookies or be informed about how they are enabled in your browser settings. All cookies except Flash cookies can be managed from your browser normally in the "Options" or "Preferences" section.',
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "You can disable them completely in which case your browser will not allow any to be installed (making it impossible for you to use all the features of the website). Or you can choose to only install those that come from certain websites, which will allow you to enjoy the advanced features of the websites that respect the privacy of your data and exclude those that do not.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "In the following links we provide you with access to the main support pages:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "In addition, the User may object to the use of certain cookies through the following services:",
    cookiesPoliciesModalBrowserConfigCookiesLI1: "Blocking cookies in Chrome",
    cookiesPoliciesModalBrowserConfigCookiesLI2: "Blocking cookies in Safari",
    cookiesPoliciesModalBrowserConfigCookiesLI3: "Blocking cookies in Firefox",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Blocking cookies in Internet Explorer",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "What happens if cookies are disabled?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "You may still browse our Website even if you reject cookies, although some of its services may be limited and your experience may therefore be less satisfactory.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "Rejecting, blocking or not allowing the cookies described in this Policy may affect the correct browsing of the content reproduced on the website.",
    cookiesPoliciesModalAcceptCookies: "Accepting the Cookies Policy",
    cookiesPoliciesModalAcceptCookiesP1:
      'Clicking on the "ACCEPT COOKIES" button implies that you accept the use of cookies.',
    cookiesPoliciesModalAcceptCookiesP2:
      "However, the Cookies Policy will be displayed at the bottom of every website page every time you log in, in order to ensure you are aware of it.",
    cookiesPoliciesModalUpgradeCookies: "Do we update our Cookies Policy?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "The information provided in this Cookies Policy may be revised due to updates or changes in the cookies management policy; likewise, the Spanish Data Protection Agency may issue instructions and/or recommendations to amend the content.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "We recommend checking this policy every time you access our Website, in order to ensure you are adequately informed about how and why we use cookies.",
    cookiesPoliciesModalRecipientCookies:
      "Who is the information received by, and how does the use of cookies affect personal data protection?",
    cookiesPoliciesModalRecipientCookiesP1:
      "For more information on the processing of your data see the ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS: "Data privacy policy",
    cookiesPoliciesModalContactInfoCookies: "Contact information",
    cookiesPoliciesModalContactInfoCookiesP1:
      "For any questions, clarifications and/or suggestions regarding cookies, please contact us by email at ",
    cookiesPoliciesModalDatatableCookieType: "Type of Cookie",
    cookiesPoliciesModalDatatableTreatmentTerm: "Processing Term",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analytical",
    cookiesPoliciesModalDatatableTypeSelf: "First-party",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Each time a User visits a Service, a tool from an external provider generates an analytical Cookie on the User's computer.This Cookie, which is only generated on the visit, will serve on future visits to CCOMMS to anonymously identify the visitor.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      'Allow anonymous identification of Browsing Users through the "Cookie" (identifies browsers and devices, not people) and therefore the approximate count of the number of visitors and their trend over time.Anonymously identify the most visited and most attractive content for Users.Know if the User who is accessing is new or repeats visit.Unless the User decides to register with CCOMMS, the "Cookie" will never be associated with any personal data that can identify him directly.Such Cookies will only be used for statistical purposes that help to optimize and improve the user experience on the site.',
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Persistent",
    cookiesPoliciesModalDatatableTypeSelfD:
      "This type of Cookies allow to expand the information displayed to each Anonymous User on the CCOMMS Services.",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Among others, the duration or frequency of display of the different sections, the interaction with them, or the navigation patterns and / or behaviors of the User are stored.",
    cookiesPoliciesModalDatatableTypeSelfT: "Persistent",
    description: "Description",
    privacyPoliciesConditionsFinalidadTitle: "Purpouse",
    accept: "Accept",
    reject: "Deny",
    save: "Save",
    cookiesPoliciesCertySign: "Cookies in Certificate validation",
    cookiesNecessaryCookieDescription:
      "This cookie is necessary and is used to set the user's preference regarding the cookie policy.",
    cookiesGAMessage:
      "Internal measurement with Google Analytics.These cookies store a unique identifier to recognize the user for future occasions visited by the website.",
    cookiesGAFGenericMessage: "Internal measurement with Google Analytics."
  },
  conditionsOfService: 'Conditions of service',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "close icon",
    zoomTRID: "zoom trid",
    zoomCSV: "zoom csv",
    sampleCertificate: "sample certificate",
    downloadButton: "download button",
    checkboxActiveUser: "checkbox",
    selectLanguage: "Select language",
    openHelpDialog: "Open support dialog",
    trid: "Write TRID",
    csv: "Write CSV",
    part1Container: "1 - Position indicator of the transaction unique identifier (trid) in the certificate profile",
    part2Container: "2 - Position indicator of the certificate unique identifier (csv) in the sample certificate",
    sampleTrid: "Unique transaction identifier (trid) in the sample certificate",
    sampleCSV: "Unique certificate identifier (csv) in the sample certificate"
  },
  activeVersion: "Current versions",
  olderVersions: "Older versions",
  show: 'show'
};
