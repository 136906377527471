export default {
  app: {
    header: {
      language: "Idioma"
    }
  },
  home: {
    title: "Verificación online de certificados",
    trid: "identificador único de transacción (trid)",
    csv: "identificador único de certificado (csv)",
    description:
      "Encha os seguintes datos para verificar a autenticidade da declaración de finalización ou certificado que obran no seu poder.",
    subtitle: "CÓDIGO DE VALIDACIÓN",
    validate: "Validar",
    resultError: "Revise os datos fornecidos",
    messagesInputs: {
      required: "Requirido",
      minimo: "Mínimo 4 caracteres",
      maximo: "Mínimo 1000 caracteres"
    }
  },
  confirmation: {
    title: "Os seus datos corresponden coa seguinte certificación",
    subtitle:
      "Pode descargar o certificado no botón inferior e comprobar que coincide coa información no seu poder.",
    download: "Descargar",
    back: "Volver",
    certificateDownloaded: "Descargouse correctamente o seu certificado"
  },
  helpDialog: {
    messageSent: {
      title: "Contactar con soporte",
      subtitle:
        "Enviouse a súa solicitude ao noso departamento de soporte; un axente poñerase en contacto con vostede o antes posible.",
      back: "Volver"
    },
    text: "Necesita axuda?",
    contactWithSupport: "Contactar con soporte",
    dialogPhrase:
      "Encha os datos e o equipo de soporte técnico responderá o antes posible. Horarios de atención, laborables:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>Luns a Venres</strong>: 8:00 h-17:00 h</p>",
    message: "Mensaxe",
    sendRequest: "Enviar solicitude",
    requiredEmailFormat: "Formato de correo electrónico incorrecto",
    requiredEmail: "Correo electrónico requirido",
    requiredMessage: "Mensaxe requirida",
    requiredName: "Nome requirido",
    email: "Correo electrónico",
    name: "Nome"
  },
  termsAndConditions: {
    title: "Termos e condicións",
    termsConditions1:
      "CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en diante, CCOMMS), na súa condición de titular e responsable da presente APP, en cumprimento do establecido no artigo 10 da Lei 34/2002, de 11 de xullo, de Servizos da Sociedade da Información e de Comercio Electrónico, pon a disposición dos usuarios a seguinte información xeral sobre esta APP:",
    companyInfo:
      "<p class='mb-1'><strong>Titular</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F</strong>: CB-86414000 </p><p class='mb-1'><strong>Domicilio social</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Datos rexistrais</strong>: Rexistro Mercantil de Madrid ao Tomo 29 801, Folio 98, Sección 8.ª, Folla número M-536210.</p><p class='mb-1'><strong>Contacto: Teléfono</strong>: 916 895 665</p><p class='mb-1'><strong>Email</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Correo electrónico do delegado de Protección de datos</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title=Delegado de Protección de datos de MailTeck>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.- OBXECTO",
    termsConditionsObjetoParrafo1:
      "As presentes Condicións Xerais de Uso (en diante, as CXU) regulan o uso da aplicación de validación de certificados (en diante, a APP) que CCOMMS pon a disposición dos usuarios que accedan co gallo de poder satisfacer a solicitude realizada.",
    termsConditionsObjetoParrafo2:
      "Polo tanto, as presentes CXU aplicaranse tanto á actividade promocional e de subministración de información que se realiza a través da APP como ao fornecemento dos servizos ofrecidos, de tal xeito que rexerán en todo momento tanto para a simple navegación pola APP como para a contratación de servizos no marco da APP, se ben esta última actividade adicionalmente someterase ás condicións xerais de contratación aplicables e ás condicións particulares que poidan existir.",
    termsConditionsUsuariosTitulo: "II.- USUARIOS",
    termsConditionsUsuariosParrafo1:
      "O acceso á APP ou o uso dela outorgan a quen os realice a condición de usuario; o usuario acepta dende ese mesmo intre, plenamente e sen ningunha reserva, as presentes CXU, así como as condicións particulares que poidan complementar, modificar ou substituír as CXU en relación con determinados servizos e contidos da APP, obrigándose a respectar as restricións de uso da páxina establecidas por CCOMMS en cada momento.",
    termsConditionsAccesoAPPTitulo: "III.- ACCESO Á APP",
    termsConditionsAccesoAPPParrafo1:
      "O acceso á APP e o uso dela teñen carácter restrinxido e requirirán o previo rexistro do usuario.",
    termsConditionsUsoAPPTitulo: "IV.- USO DA APP",
    termsConditionsUsoAPPParrafo1:
      "O usuario comprométese a utilizar a APP e os seus servizos e contidos sen contravir a lexislación vixente, a boa fe, os usos xeralmente aceptados e a orde pública. Así mesmo, queda prohibido o uso da APP con fins ilícitos ou lesivos para CCOMMS ou calquera terceiro, ou que poidan de calquera xeito causar prexuízo ou impedir o correcto funcionamento da APP.",
    termsConditionsResposabilidadTitulo:
      "V-. RESPONSABILIDADE DO USUARIO POR DANOS E PREXUÍZOS",
    termsConditionsResposabilidadParrafo1:
      "O uso da APP farase baixo a única e exclusiva responsabilidade do usuario. Esta responsabilidade estenderase ao uso por parte do usuario ou dun terceiro de calquera contrasinal ou similar asignados para acceder á APP ou os seus servizos.",
    termsConditionsResposabilidadParrafo2:
      "Sen prexuízo do anterior, CCOMMS resérvase o dereito a denegar ou inhabilitar, en calquera momento e sen necesidade de aviso previo, o acceso á APP ou o acceso restrinxido para aqueles usuarios que incumpran estas CXU ou as normas establecidas por CCOMMS ou os seus colaboradores, ou que perturben o bo funcionamento, a imaxe, a credibilidade ou o prestixio de CCOMMS ou os seus colaboradores.",
    termsConditionsResposabilidadParrafo3:
      "Neste sentido, CCOMMS resérvase a facultade de cancelar ou restrinxir o acceso á APP para determinados usuarios en caso de observar calquera conduta que, ao seu xuízo, resulte contraria a estas condicións de uso, la lei ou as normas establecidas por CCOMMS ou os seus colaboradores, ou que perturbe o bo funcionamento, a imaxe, a credibilidade ou o prestixio de CCOMMS ou os seus colaboradores. Así mesmo, CCOMMS poderá esixir responsabilidade aos usuarios polos danos e prexuízos derivados dun mal uso da APP.",
    termsConditionsResposabilidadParrafo4:
      "CCOMMS non garante a licitude, fiabilidade e utilidade, así como a veracidade ou exactitude, dos contidos que os usuarios transmitan durante a utilización da APP.",
    termsConditionsResposabilidadParrafo5:
      "CCOMMS non controla o uso que os usuarios fagan da APP nin garante que este uso cumpra as presentes Condicións Xerais.",
    termsConditionsResposabilidadParrafo6:
      "CCOMMS non controla nin garante que nos servizos fornecidos por terceiros a través da APP non existan virus informáticos que poidan producir alteracións no seu sistema informático (software e hardware) ou nos documentos e ficheiros electrónicos almacenados no seu sistema informático.",
    termsConditionsPropiedadIntelectualTitulo: "VI.- PROPIEDADE INTELECTUAL",
    termsConditionsPropiedadIntelectualParrafo1:
      'Calquera uso ou modificación do material ou dos contidos para calquera fin distinto do autorizado nas Condicións Xerais consideraranse unha infracción das leis internacionais de "copyright", que protexen os dereitos de autor.',
    termsConditionsPropiedadIntelectualParrafo2:
      "Respecto aos contidos, prohíbese:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "A súa reprodución, copia, distribución, modificación ou manipulación, de calquera xeito e con calquera finalidade, a menos que se dispoña da autorización previa por escrito dos lexítimos titulares ou que estea permitido por lei.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "Calquera vulneración dos dereitos de CCOMMS ou dos lexítimos titulares.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "A súa utilización para todo tipo de fins comerciais ou publicitarios distintos dos estritamente permitidos.",
    termsConditionsPropiedadIntelectualParrafo3:
      "CCOMMS garante que os contidos, incluídos os de propiedade intelectual, non son de carácter ilícito nin infrinxen a normativa vixente. Polo tanto, os contidos da APP non terán carácter xenófobo, pornográfico, discriminatorio nin racista, nin fomentarán de calquera outro xeito a violencia.",
    termsConditionsPropiedadIntelectualParrafo4:
      "Ademais, CCOMMS adoptará as medidas legais que considere convenientes para impedir calquera tipo de conduta contraria á lei ou inmoral.",
    termsConditionsModificacionUnilateralTitulo:
      "VII.- MODIFICACIÓN UNILATERAL",
    termsConditionsModificacionUnilateralParrafo1:
      "CCOMMS poderá modificar unilateralmente e sen previo aviso, sempre que o considere oportuno, a estrutura e o deseño da APP, así como cambiar ou eliminar os servizos, os contidos e as condicións de acceso ou uso da APP.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- EXCLUSIÓN DE GARANTÍAS E RESPONSABILIDADE",
    termsConditionsExclusionGarantiasParrafo1:
      "CCOMMS non outorga ningunha garantía nin se fai responsable, en ningún caso, respecto aos danos e prexuízos de calquera tipo que puideran derivarse de:",
    termsConditionsExclusionGarantiasListaELemento1:
      "A non observación da lei, a moral e os bos costumes, así como a alteración da orde pública ou o uso dos produtos ou servizos ofertados de xeito pouco dilixente ou incorrecto, ou con fins ilícitos ou contrarios ao establecido nas presentes CXU ou nas condicións que sexan aplicables en cada caso.",
    termsConditionsExclusionGarantiasListaELemento2:
      "A adquisición ou contratación de servizos, bens ou produtos de terceiros a través dun acceso na APP de CCOMMS, especialmente naqueles casos en que o proceso de compra ou contratación se realice directamente na APP do terceiro, aínda que aparezan distintivos ou un «frame» cos elementos gráficos da APP (branding).",
    termsConditionsExclusionGarantiasListaELemento3:
      "A falta de dispoñibilidade, mantemento e funcionamento efectivo da APP ou os seus servizos ou contidos.",
    termsConditionsExclusionGarantiasListaELemento4:
      "A falta de utilidade, adecuación ou validez da APP ou dos seus servizos ou contidos para necesidades, actividades ou resultados concretos ou as expectativas dos usuarios.",
    termsConditionsExclusionGarantiasListaELemento5:
      "A existencia de virus ou programas maliciosos ou lesivos nos contidos.",
    termsConditionsExclusionGarantiasListaELemento6:
      "A recepción, obtención, almacenamento, difusión ou transmisión por parte dos usuarios dos contidos.",
    termsConditionsExclusionGarantiasListaELemento7:
      "A ilicitude ou falta de veracidade, exactitude, fiabilidade, pertinencia, actualidade e exhaustividade dos contidos e informacións transmitidos ou postos a disposición dos usuarios, incluíndo as informacións e servizos fornecidos por terceiros ou polos usuarios a través da APP.",
    termsConditionsExclusionGarantiasListaELemento8:
      "O incumprimento por parte de terceiros das súas obrigas ou compromisos en relación cos servizos fornecidos aos usuarios a través da APP.",
    termsConditionsExclusionGarantiasListaELemento9:
      "Os contidos, ficheiros, informacións, publicidade, opinións, conceptos e imaxes que non dependan da APP nin estean xestionados por CCOMMS. Así mesmo, o mal uso que se faga dos contidos da APP, cuxa exclusiva responsabilidade recae na persoa que acceda a eles ou os utilice.",
    termsConditionsExclusionGarantiasListaELemento10:
      "O uso indebido das claves de usuario para acceder aos contidos do servizo da APP que as requiran e as consecuencias de calquera tipo derivadas do mal uso ou da perda ou esquecemento por parte dos usuarios, e do uso indebido por parte de terceiros non autorizados.",
    termsConditionsExclusionGarantiasListaELemento11:
      "A incapacidade de calquera usuario ou a suplantación da personalidade dun terceiro por parte do usuario.",
    termsConditionsExclusionGarantiasListaELemento12:
      "Os contidos fornecidos polos usuarios.",
    termsConditionsProteccionDatosTitulo: "IX.-  PROTECCIÓN DE DATOS",
    termsConditionsProteccionDatosParrafo1:
      "Pode consultar a información detallada sobre protección de datos na nosa páxina de",
    termsConditionsLeyJurisdiccionTitulo: "X.- LEI APLICABLE E XURISDICIÓN",
    termsConditionsLeyJurisdiccionParrafo1:
      "Todas as condicións, xerais e particulares, aplicables á presente APP réxense pola normativa española. Todos os conflitos, controversias ou situacións derivadas das condicións xerais e particulares resolveranse nos xulgados e tribunais da Vila de Madrid, renunciando expresamente as partes ao seu foro."
  },
  cookies: {
    cookiesPoliciesCompliance: "Política de rastros",
    cookiesPoliciesIntroText:
      "Este sitio web utiliza rastros para mellorar a experiencia de navegación por el. Pode cambiar estes axustes en calquera momento. Os rastros clasificados como necesarios almacénanse no navegador e son esenciais para as funcións básicas do sitio web. Os rastros de terceiros almacénanse no navegador só se dá o seu consentimento e axúdannos a analizar e comprender como se utiliza o sitio web. Pode optar por non recibir estes rastros; non obstante, a exclusión voluntaria dalgún destes rastros pode facer que algunhas funcións deixen de estar dispoñibles. Para obter información sobre a eliminación dos rastros, consulte a axuda do navegador.",
    cookiesPoliciesNecessaryCookies: "Rastros necesarios",
    cookiesPoliciesNecessaryCookiesText:
      "Este tipo de rastros son absolutamente esenciais para que o sitio web funcione correctamente. Esta categoría só inclúe rastros que garanten funcións básicas e características de seguridade do sitio web e non recompilan nin almacenan ningunha información persoal sobre os usuarios do sitio web.",
    cookiesPoliciesUnnecessaryCookies: "Rastros non necesarios",
    cookiesPoliciesUnnecessaryCookiesText:
      "Denomínanse rastros non necesarios aqueles que poden non ser especialmente necesarios para que o sitio web funcione e se utilizan especificamente para recompilar datos persoais do usuario a través de análises, anuncios e outros contidos integrados. É obrigatorio obter o consentimento do usuario antes de executar estes rastros no sitio web.",
    cookiesPoliciesGoogleAnalyticsCookies: "Rastros de Google Analytics",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "Para obter máis información sobre este tipo de rastros, pode consultar o documento «Uso dos rastros de Google Analytics en sitios web»",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Manter rastros activos",
    cookiesNotificationBarText:
      "Utilizamos rastros, tanto propios como de terceiros, para computar as visitas, garantir as funcións do sitio web e analizar a navegación por este. A continuación, pode aceptar os rastros ou configurar/rexeitar o seu uso. Pode obter máis información sobre o titular da web, que é responsable do tratamento dos rastros e dos seus datos de contacto, na nosa",
    cookiesNotificationBarConfig: "Configurar",
    cookiesPoliciesModalIntro: "Introdución",
    cookiesPoliciesModalIntroP1:
      "Este sitio web utiliza rastros ou tecnoloxías similares que almacenan e recuperan información durante a navegación para mellorar e optimizar a experiencia do usuario.",
    cookiesPoliciesModalIntroP2:
      "En xeral, estas tecnoloxías poden servir para fins moi diversos, como, por exemplo, recoñecer os usuarios, obter información sobre hábitos de navegación ou personalizar o xeito en que se amosa o contido.",
    cookiesPoliciesModalIntroP3:
      "De conformidade co Real Decreto 13/2012 e a súa afectación no artigo 22.2 da Lei de Servizos da Sociedade da Información e Comercio Electrónico, mediante a presente política comunícaselle que rastros, propios ou de terceiros, exentos ou non do deber de notificación, recompila CUSTOMER COMMUNICATIONS TECKNALIA, S.L. a través dos distintos medios ou canles de comunicación, así como por que medios se poden rexeitar, permitir ou bloquear os devanditos rastros.",
    cookiesPoliciesModalIntroP4:
      "A continuación, fornécese información detallada sobre que son os rastros, que tipos se utilizan neste sitio web, como se poden desactivar nun navegador e como bloquear especificamente a instalación de rastros de terceiros.",
    cookiesPoliciesModalResponsibility:
      "Quen é o responsable do uso dos rastros no presente sitio web?",
    cookiesPoliciesModalResponsibilityP1:
      "O responsable do uso dos rastros é CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en diante, CCOMMS), con NIF número B-86414000 e con domicilio social en Leganés (Madrid), Avenida de la Recomba, 14, debidamente inscrita no Rexistro Mercantil de Madrid ao Tomo 29 801, Folio 98, Sección 8.ª, Folla número M-536210.",
    cookiesPoliciesModalResponsibilityP2:
      "Correo electrónico do delegado de Protección de datos.",
    cookiesPoliciesModalWhatIsCookie: "Que é un rastro?",
    cookiesPoliciesModalWhatIsCookieP1:
      "Un rastro é un dato, ficheiro ou dispositivo de almacenamento e recuperación de datos (DARD) que o navegador web almacena de xeito automático no dispositivo (smartphone, tableta ou televisor conectado) do usuario cando este accede a páxinas web e contas de redes sociais ou recibe un correo electrónico.",
    cookiesPoliciesModalWhatIsCookieP2:
      "Os rastros permiten almacenar e recuperar de xeito automatizado datos sobre o uso que se fixo dos citados medios dixitais e sobre o equipo ou dispositivo dende o que se accede a eles.",
    cookiesPoliciesModalWhatIsCookieP3:
      "Un rastro son só datos que se almacenan no ordenador do usuario, polo que non se trata de software nin de fragmentos de código, só de datos. Por tanto, en principio os rastros non poden transmitir nin executar virus, nin instalar malware como troianos ou programas de espionaxe.",
    cookiesPoliciesModalCookieTypes:
      "Que tipo de rastros hai na páxina web e a súa finalidade",
    cookiesPoliciesModalCookiesInstall:
      "Que rastros se poden instalar na web de verificación de certificados?",
    cookiesPoliciesModalCookiesInstallP1:
      "O acceso á web de verificación de certificados implica que se poidan instalar os rastros, propios ou de terceiros, que se detallan no seguinte cadro:",
    cookiesPoliciesModalBrowserConfigCookies:
      "Como configurar os rastros no navegador",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      "Ten a opción de non recibir rastros ou de que lle comuniquen a fixación dos mesmos, o que deberá axustar na configuración do navegador; todos os rastros, excepto os de Flash, poden xestionarse dende o navegador, normalmente nos apartados «Opcións» ou «Preferencias».",
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "Pode desactivalos de todo, en cuxo caso o navegador non permitirá que se instale ningún (imposibilitando o uso de todas as funcións da páxina web), ou desactivar só os que procedan de determinadas webs, de xeito que poida gozar das funcións avanzadas das webs que respectan a privacidade dos datos, excluíndo só as que non o fagan.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "Mediante as seguintes ligazóns poderá acceder ás principais páxinas de soporte:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "Así mesmo, o usuario pode opoñerse ao uso de determinados rastros mediante os seguintes servizos:",
    cookiesPoliciesModalBrowserConfigCookiesLI1: "Bloqueo de rastros en Chrome",
    cookiesPoliciesModalBrowserConfigCookiesLI2: "Bloqueo de rastros en Safari",
    cookiesPoliciesModalBrowserConfigCookiesLI3:
      "Bloqueo de rastros en Firefox",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Bloqueo de rastros en Internet Explorer",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "Que ocorre se se inhabilitan os rastros?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "Se rexeita os rastros, poderá seguir navegando polo sitio web, aínda que o uso dalgúns servizos podería quedar limitado e, por tanto, a súa experiencia podería resultar menos satisfactoria.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "O rexeitamento, bloqueo ou non aceptación dos rastros descritos na presente política poden afectar a correcta navegación polos contidos do sitio web.",
    cookiesPoliciesModalAcceptCookies: "Aceptación da política de rastros",
    cookiesPoliciesModalAcceptCookiesP1:
      "Premendo o botón «ACEPTAR RASTROS», indica que acepta o uso dos rastros.",
    cookiesPoliciesModalAcceptCookiesP2:
      "Non obstante, cada vez que inicie sesión, aparecerá a política de rastros na parte inferior das páxinas do portal co gallo de que sexa consciente dela.",
    cookiesPoliciesModalUpgradeCookies:
      "Actualizamos a nosa política de rastros?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "É posible que a información fornecida na presente política de rastros poida sufrir modificacións por mor de actualizacións ou cambios no tratamento dos rastros ou da publicación de novas instrucións ou recomendacións por parte da Axencia Española de Protección de Datos.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "Por iso, recomendámoslle revisar esta política cada vez que acceda ao sitio web co gallo de estar ao día de como e para que usamos os rastros.",
    cookiesPoliciesModalRecipientCookies:
      "Quen son os destinatarios da información e como afecta o uso de rastros á protección dos datos persoais?",
    cookiesPoliciesModalRecipientCookiesP1:
      "Para máis información sobre o tratamento dos seus datos, pode consultar a ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS:
      "Política de protección de datos",
    cookiesPoliciesModalContactInfoCookies: "Información de contacto",
    cookiesPoliciesModalContactInfoCookiesP1:
      "Para calquera dúbida, aclaración ou suxestión sobre os rastros, debe contactar a través do correo electrónico ",
    cookiesPoliciesModalDatatableCookieType: "Tipo de rastro",
    cookiesPoliciesModalDatatableTreatmentTerm: "Prazo de tratamento",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analíticos",
    cookiesPoliciesModalDatatableTypeSelf: "Propios",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Cada vez que un usuario visita un servizo, unha ferramenta dun provedor externo xera un rastro analítico no seu ordenador. Este rastro, que só se xera na visita, utilizarao CCOMMS para, nas seguintes visitas, identificar de xeito anónimo o visitante.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      "Permitir a identificación anónima dos usuarios navegantes a través do rastro (identifica navegadores e dispositivos, pero non persoas) e, polo tanto, a contabilización aproximada do número de visitantes coas tendencias temporais. Identificar de xeito anónimo os contidos máis visitados e atractivos para os usuarios. Saber se o usuario que está accedendo é novo ou repite visita. Salvo que o usuario decida rexistrarse en CCOMMS, o rastro nunca irá asociado a ningún dato de carácter persoal que poida identificalo directamente. Os devanditos rastros só se utilizarán con fins estatísticos que axuden á optimización e mellora da experiencia dos usuarios no sitio.",
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Persistentes",
    cookiesPoliciesModalDatatableTypeSelfD:
      "Este tipo de rastro permite ampliar a información que se amosa a cada usuario anónimo nos servizos de CCOMMS.",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Almacénanse, entre outros datos, a duración ou frecuencia da visualización dos distintos apartados, a interacción cos mesmos ou os patróns de navegación ou os comportamentos do usuario.",
    cookiesPoliciesModalDatatableTypeSelfT: "Persistentes",
    description: "Descrición",
    privacyPoliciesConditionsFinalidadTitle: "Finalidade",
    accept: "Aceptar",
    reject: "Rexeitar",
    save: "Gardar",
    cookiesPoliciesCertySign: "Rastros na verificación de certificados",
    cookiesNecessaryCookieDescription:
      "Este rastro é necesario e utilízase para establecer a preferencia do usuario respecto á política de rastros.",
    cookiesGAMessage:
      "Medición interna con Google Analytics. Estes rastros almacenan un identificador único para recoñecer o usuario cando no futuro volva visitar o sitio web.",
    cookiesGAFGenericMessage: "Medición interna con Google Analytics."
  },
  conditionsOfService: 'Condicións do servizo',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "icona pechar",
    zoomTRID: "zoom trid",
    zoomCSV: "ampliar csv",
    sampleCertificate: "Certificado de mostra",
    downloadButton: "botón de descarga",
    checkboxActiveUser: "Caixa de verificación",
    selectLanguage: "Seleccionar idioma",
    openHelpDialog: "Abrir diálogo de soporte",
    trid: "Escribe TRID",
    csv: "Escribir CSV",
    part1Container: "1 - Indicador de posición do identificador único de transacción (trid) no modelo de certificado",
    part2Container: "2 - Indicador de posición do identificador único do certificado (csv) no modelo de certificado",
    sampleTrid: "Identificador de transacción único (trid) no certificado de mostra",
    sampleCSV: "Identificador único do certificado (csv) no certificado de mostra"
  }
};
